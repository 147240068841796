import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vehicles: []
}
const vehicleSlice = createSlice({
    name: "vehicle",
    initialState: initialState,
    reducers: {
        setVehicles: (state, action) => {
            state.vehicles = action.payload.results;
        },
        deleteVehicle: (state, action) => {
            state.vehicles = state.vehicles.filter(vehicle => vehicle.id !== action.payload);
        },
        editVehicle : (state, action) => {
            const id = action.payload.id;
            const { fid, imei, capacity, capacity_type, cc, color, plate, provider, seats, vin, year } = action.payload;
            const vehicle = state.vehicles.find(vehicle => vehicle.id === id);
            vehicle.fid = fid;
            vehicle.imei = imei;
            vehicle.capacity = capacity;
            vehicle.capacity_type = capacity_type;
            vehicle.cc = cc;
            vehicle.color = color;
            vehicle.plate = plate;
            vehicle.provider = provider;
            vehicle.seats = seats;
            vehicle.vin = vin;
            vehicle.year = year;
        },
        addVehicle: (state, action) => {
            state.vehicles.unshift(action.payload);
        }
    }
});

export const { setVehicles, deleteVehicle, editVehicle, addVehicle } = vehicleSlice.actions;
export default vehicleSlice.reducer;