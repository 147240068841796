import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    fid: 1,
    imei: null,
    capacity: null,
    capacity_type: 'cc',
    cc: null,
    color: null,
    plate: null,
    provider: 'teltonika',
    seats: null,
    vin: null,
    year: null
}
const tempVehicleSlice = createSlice({
    name: "tempVehicle",
    initialState: initialState,
    reducers: {
        setTempVehicle: (state, action) => {
            state.id = action.payload.id || state.id;
            state.fid = action.payload.fid || state.fid;
            state.imei = action.payload.imei || state.imei;
            state.capacity = action.payload.capacity || state.capacity;
            state.capacity_type = action.payload.capacity_type || state.capacity_type;
            state.cc = action.payload.cc || state.cc;
            state.color = action.payload.color || state.color;
            state.plate = action.payload.plate || state.plate;
            state.provider = action.payload.provider || state.provider;
            state.seats = action.payload.seats || state.seats;
            state.vin = action.payload.vin || state.vin;
            state.year = action.payload.year || state.year;
            
        },
        clearTempVehicle: (state, action) => {
            state.id = null;
            state.fid = null;
            state.imei = null;
            state.capacity = null;
            state.capacity_type = null;
            state.cc = null;
            state.color = null;
            state.plate = null;
            state.provider = null;
            state.seats = null;
            state.vin = null;
            state.year = null;
        }
    }
});

export const { setTempVehicle, clearTempVehicle } = tempVehicleSlice.actions;
export default tempVehicleSlice.reducer;
