import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tempSystems: {
        cpu: [
            {time: '', cpu: ''}, {time: '', cpu: ''}, {time: '', cpu: ''}, {time: '', cpu: ''}, {time: '', cpu: ''}, {time: '', cpu: ''}, {time: '', cpu: ''}, {time: '', cpu: ''}, {time: '', cpu: ''}, {time: '', cpu: ''}
        ],
        ram: [
            {time: '', ram: ''}, {time: '', ram: ''}, {time: '', ram: ''}, {time: '', ram: ''}, {time: '', ram: ''}, {time: '', ram: ''}, {time: '', ram: ''}, {time: '', ram: ''}, {time: '', ram: ''}, {time: '', ram: ''}
        ],
        processes: [],
        disk: [
            {name: 'empty', value: '', color: '#007500'},
            {name: 'used', value: '', color: '#750000'},
        ],
        uptime: '',
        mysql_status: {}
    }
}
const tempSystemSlice = createSlice({
    name: "tempSystem",
    initialState: initialState,
    reducers: {
        setTempSystems: (state, action) => {
            state.tempSystems.cpu.shift();
            state.tempSystems.cpu.push({time: action.payload.time, cpu: action.payload.cpu});
            state.tempSystems.ram.shift();
            state.tempSystems.ram.push({time: action.payload.time, ram: action.payload.ram});
            state.tempSystems.processes = action.payload.processes;
            state.tempSystems.disk[0].value = parseFloat((100 - action.payload.disk).toFixed(2));
            state.tempSystems.disk[1].value = parseFloat(action.payload.disk.toFixed(2));
            state.tempSystems.uptime = action.payload.uptime;
            state.tempSystems.mysql_status = action.payload.mysql_status;
        }
    }
});

export const { setTempSystems } = tempSystemSlice.actions;
export default tempSystemSlice.reducer;