import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    datatable: '',
    isOpen: false,
    rowData: null
}
const deletePopupSlice = createSlice({
    name: "deletePopup",
    initialState: initialState,
    reducers: {
        setDeletePopup: (state, action) => {
            state.datatable = action.payload.datatable;
            state.isOpen = action.payload.isOpen;
            state.rowData = action.payload.rowData;
        }
    }
});

export const { setDeletePopup } = deletePopupSlice.actions;
export default deletePopupSlice.reducer;