import React from 'react'
import { Card, CardHeader, CardContent } from '@mui/material'
import { Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell} from 'recharts';

function DiskChart({ disk }) {
    return (
        <Card sx={{ width: '100%', height: '100%' }} >
            <CardHeader title='Disk Usage (%)' />
            <CardContent>
                <ResponsiveContainer height='100%' aspect={5 / 4.45}>
                    <PieChart>
                        <Pie
                            dataKey="value"
                            data={disk}
                            outerRadius={150}
                            fill="#8884d8"
                            label
                        >
                            {
                                disk.map((entry, index) => <Cell key={index} fill={entry.color} />)
                            }
                        </Pie>
                        <Legend verticalAlign='top' />
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    )
}

export default DiskChart