import React, {useEffect} from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setSystems } from '../../features/system/systemSlice';
import { setTempSystems } from '../../features/temp/tempSystemSlice';
import moment from 'moment';
import CpuChart from './charts/CpuChart';
import RamChart from './charts/RamChart';
import Processes from './charts/Processes';
import DiskChart from './charts/DiskChart';
import Uptime from './charts/Uptime';
import MysqlStatus from './charts/MysqlStatus';
import { setError } from '../../features/error/errorSlice';

function SystemDashboard() {
    const dispatch = useDispatch()
    const cpu = useSelector(state => state.tempSystem.tempSystems.cpu);
    const ram = useSelector(state => state.tempSystem.tempSystems.ram);
    const processes = useSelector(state => state.tempSystem.tempSystems.processes);
    const disk = useSelector(state => state.tempSystem.tempSystems.disk);
    const uptime = useSelector(state => state.tempSystem.tempSystems.uptime);
    const mysql_status = useSelector(state => state.tempSystem.tempSystems.mysql_status);

    useEffect(() => {
        setInterval(() => {
            axios.get(`${process.env.REACT_APP_API_URL}/system`,{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => {
                dispatch(setSystems( res.data ))
                const values = res.data.results;
                dispatch(setTempSystems({
                    time: moment().format('HH:mm:ss'), 
                    cpu: values[0].cpu,
                    ram: values[0].ram,
                    processes: values[0].processes,
                    disk: values[0].disk,
                    uptime: values[0].uptime,
                    mysql_status: values[0].mysql_status,
                }))
            })
            .catch(err => {
                if(err.response.status === 403){
                    localStorage.removeItem('token')
                    window.location.href = '/'
                }else{
                    dispatch(setError({
                        isOpen:true, 
                        title: `Error ${err.response.status}`,
                        message: err.response.data.description
                    }));
                }
            })
        }, 5000);
    }, [dispatch])
    
    return (
        <div className='container'>
            <div className='row h-40'>
                <div className='col-6 pr-1'>
                    <CpuChart cpu={cpu}/>
                </div>
                <div className='col-6 pr-1 pl-1'>
                    <RamChart ram={ram}/>
                </div>
            </div>
            <div className='row h-60 pt-2 pb-2'>
                <div className='col-4 pr-1'>
                    <Processes processes={processes}/>
                </div>
                <div className='col-4 pr-1 pl-1'>
                    <DiskChart disk={disk}/>
                </div>
                <div className='col-4 pl-1 pr-1'>
                    <div className='row h-25 w-100'>
                        <Uptime uptime={uptime}/>
                    </div>
                    <div className='row w-100 h-75 pt-1'>
                        <MysqlStatus mysql_status={mysql_status}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SystemDashboard