import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sims: []
}
const simSlice = createSlice({
    name: "sim",
    initialState: initialState,
    reducers: {
        setSims: (state, action) => {
            state.sims = action.payload.results;
        },
        deleteSim: (state, action) => {
            state.sims = state.sims.filter(sim => sim.id !== action.payload);
        },
        editSim: (state, action) => {
            const id = action.payload.id;
            const { user, activation_date, active, imei, provider, phone_number, plate, price, renewal_date, sim_vin, type } = action.payload;
            const sim = state.sims.find(sim => sim.id === id);
            sim.activation_date = activation_date;
            sim.user = user;
            sim.active = active;
            sim.imei = imei;
            sim.provider = provider;
            sim.phone_number = phone_number;
            sim.plate = plate;
            sim.price = price;
            sim.renewal_date = renewal_date;
            sim.sim_vin = sim_vin;
            sim.type = type;
        },
        addSim: (state, action) => {
            state.sims.unshift(action.payload);
        }
    }
});

export const { setSims, deleteSim, editSim, addSim } = simSlice.actions;
export default simSlice.reducer;