import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    users: []
}
const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload.results;
        },
        deleteUser: (state, action) => {
            state.users = state.users.filter(user => user.id !== action.payload);
        },
        editUser : (state, action) => {
            const { id, validated, gdpr_compliant, email } = action.payload;
            const user = state.users.find(user => user.id === id);
            user.validated = validated;
            user.gdpr_compliant = gdpr_compliant;
            user.email = email;
        },
        addUser: (state, action) => {
            state.users.unshift(action.payload);
        }
    }
});

export const { setUsers, editUser, deleteUser, addUser } = userSlice.actions;
export default userSlice.reducer;