import React from 'react'
import SimCardIcon from '@mui/icons-material/SimCard';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SecurityIcon from '@mui/icons-material/Security';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import { useDispatch } from 'react-redux';
import { setSelected } from '../features/sidebar/sidebarSlice';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

function Sidebar() {
    const dispatch = useDispatch();

    const handleClick = (selected) => {
        dispatch(setSelected(selected));
        const selected_element = document.getElementById(selected);
        const elements = document.getElementsByClassName('active');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('active');
        }
        selected_element.classList.add('active');
    }

    const logout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }

    const handleMouseEnter = () => {
        const sidebar = document.getElementById('home_sidebar');
        const dashboard = document.getElementsByClassName('dashboard')[0];
        dashboard.classList.add('dashboard-sidebar-is-open');
        dashboard.classList.remove('dashboard-sidebar-is-closed');
        sidebar.classList.add('sidebar-open');
        sidebar.classList.remove('sidebar-close');
    }

    const handleMouseLeave = () => {
        const sidebar = document.getElementById('home_sidebar');
        const dashboard = document.getElementsByClassName('dashboard')[0];
        dashboard.classList.remove('dashboard-sidebar-is-open');
        dashboard.classList.add('dashboard-sidebar-is-closed');
        sidebar.classList.remove('sidebar-open');
        sidebar.classList.add('sidebar-close');
    }

    return (
        <div id='home_sidebar' className='home-sidebar' onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
            <List component='nav' className='h-100 d-flex flex-column'>
                <ListItemButton className='active mxh-5r' id='home' onClick={()=>handleClick('home')}>
                    <ListItemIcon>
                        <HomeIcon className='sidebar-icon' />
                    </ListItemIcon>
                    <ListItemText primary='Home' />
                </ListItemButton>
                <Divider />
                <ListItemButton className='mxh-5r' id='users' onClick={()=>handleClick('users')}>
                    <ListItemIcon>
                        <GroupIcon className='sidebar-icon'/>
                    </ListItemIcon>
                    <ListItemText primary='Users' />
                </ListItemButton>
                <ListItemButton className='mxh-5r' id='vehicles' onClick={()=>handleClick('vehicles')}>
                    <ListItemIcon>
                        <DirectionsCarIcon className='sidebar-icon'/>
                    </ListItemIcon>
                    <ListItemText primary='Vehicles' />
                </ListItemButton>
                <ListItemButton className='mxh-5r' id='system' onClick={()=>handleClick('system')}>
                    <ListItemIcon>
                        <SecurityIcon className='sidebar-icon'/>
                    </ListItemIcon>
                    <ListItemText primary='System' />
                </ListItemButton>
                <ListItemButton className='mxh-5r' id='sims' onClick={()=>handleClick('sims')}>
                    <ListItemIcon>
                        <SimCardIcon className='sidebar-icon'/>
                    </ListItemIcon>
                    <ListItemText primary='Sims' />
                </ListItemButton>
                <ListItemButton className='mxh-5r' id='certificates' onClick={()=>handleClick('certificates')}>
                    <ListItemIcon>
                        <SettingsSuggestIcon className='sidebar-icon'/>
                    </ListItemIcon>
                    <ListItemText primary='Certificates' />
                </ListItemButton>
                <ListItemButton className='mxh-5r' id='companies' onClick={()=>handleClick('companies')}>
                    <ListItemIcon>
                        <ApartmentIcon className='sidebar-icon'/>
                    </ListItemIcon>
                    <ListItemText primary='Companies' />
                </ListItemButton>
                <ListItemButton className='mxh-5r mt-auto' id='logout' onClick={logout}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary='Logout' />
                </ListItemButton>
            </List>
        </div>
    )
}

export default Sidebar