import React from 'react'
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { setEditPopup } from '../../../features/popup/editPopupSlice';
import EditPopup from '../modal/EditPopup';
import { clearTempUser } from '../../../features/temp/tempUserSlice';
import { clearTempVehicle } from '../../../features/temp/tempVehicleSlice';
import { clearTempSim } from '../../../features/temp/tempSimSlice';
import { clearTempCertificate } from '../../../features/temp/tempCertificateSlice';
import { clearTempCompany } from '../../../features/temp/tempCompanySlice';

function MultiEditButton({ rowData, dt_name }) {
    const dispatch = useDispatch();
    const editElements = () => {
        //* First initialize the temp states of the entities
        switch(dt_name) {
            case 'user':
                dispatch(clearTempUser());
                break;
            case 'vehicle':
                dispatch(clearTempVehicle());
                break;
            case 'sim':
                dispatch(clearTempSim());
                break;
            case 'certificate':
                dispatch(clearTempCertificate());
                break;
            case 'company':
                dispatch(clearTempCompany());
                break;
            default:
                break;
        }
        dispatch(setEditPopup({
            datatable: dt_name,
            rowData: rowData,
            isOpen: true,
            multiple: true
        }))
    }
    return (
        <>
            <Fab style={{zIndex: 1, marginRight: 10}} size='small' color='primary' variant='extended' onClick={()=>editElements()}>
                <EditIcon />
                Edit Selected
            </Fab>
            {useSelector(state => state.editPopup.isOpen) && <EditPopup />}
        </>
    )
}

export default MultiEditButton