import React from 'react'
import UserAddModalBody from './addModalComponents/UserAddModalBody';
import VehicleAddModalBody from './addModalComponents/VehicleAddModalBody';
import SimAddModalBody from './addModalComponents/SimAddModalBody';

function AddModalBody({datatable}) {
    switch (datatable) {
        case 'user':
            return (
                <UserAddModalBody />
            )
        case 'vehicle':
            return (
                <VehicleAddModalBody />
            )
        case 'sim':
            return (
                <SimAddModalBody />
            )
        case 'certificate':
            return (
                <div>{datatable}</div>
            )
        default:
            return (
                <div>{datatable}</div>
            )
    }
}

export default AddModalBody