import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    companies: []
}
const companySlice = createSlice({
    name: "company",
    initialState: initialState,
    reducers: {
        setCompanies: (state, action) => {
            state.companies = action.payload.results;
        },
        deleteCompany: (state, action) => {
            state.companies = state.companies.filter(certificate => certificate.id !== action.payload);
        },
        editCompany : (state, action) => {
            const { id, name, alias, type, vat, tax_office, phone_code_prefix, phone, emergency_phone, address, postcode, city, country, currency, locales, latitude, longitude, cardlink, viva, viva_native, paypal, paymentexpress, gocardless, mass_cancellation, mass_lock, disable_immobilizer_if_reservation, unreserved_vehicle_alert, battery_level_alert, fuel_level_alert, lost_signal_alert, driver_license_expiration_alert, speed_limit_alert, partial_charge_reservation, active, access_token, firebase_server_key, android_version, ios_version, force_mobile_app_update, member_personal_required_fields, customer_billing_required_fields, send_invoice_to } = action.payload;
            const company = state.companies.find(company => company.id === id);
            company.name = name;
            company.alias = alias;
            company.type = type;
            company.vat = vat;
            company.tax_office = tax_office;
            company.phone_code_prefix = phone_code_prefix;
            company.phone = phone;
            company.emergency_phone = emergency_phone;
            company.address = address;
            company.postcode = postcode;
            company.city = city;
            company.country = country;
            company.currency = currency;
            company.locales = locales;
            company.latitude = latitude;
            company.longitude = longitude;
            company.cardlink = cardlink;
            company.viva = viva;
            company.viva_native = viva_native;
            company.paypal = paypal;
            company.paymentexpress = paymentexpress;
            company.gocardless = gocardless;
            company.mass_cancellation = mass_cancellation;
            company.mass_lock = mass_lock;
            company.disable_immobilizer_if_reservation = disable_immobilizer_if_reservation;
            company.unreserved_vehicle_alert = unreserved_vehicle_alert;
            company.battery_level_alert = battery_level_alert;
            company.fuel_level_alert = fuel_level_alert;
            company.lost_signal_alert = lost_signal_alert;
            company.driver_license_expiration_alert = driver_license_expiration_alert;
            company.speed_limit_alert = speed_limit_alert;
            company.partial_charge_reservation = partial_charge_reservation;
            company.active = active;
            company.access_token = access_token;
            company.firebase_server_key = firebase_server_key;
            company.android_version = android_version;
            company.ios_version = ios_version;
            company.force_mobile_app_update = force_mobile_app_update;
            company.member_personal_required_fields = member_personal_required_fields;
            company.customer_billing_required_fields = customer_billing_required_fields;
            company.send_invoice_to = send_invoice_to;
        },
        addCompany: (state, action) => {
            state.companies.unshift(action.payload);
        }
    }
});

export const { setCompanies, deleteCompany, editCompany, addCompany } = companySlice.actions;
export default companySlice.reducer;