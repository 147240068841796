import React, {useEffect} from 'react'
import { TextField, MenuItem } from '@mui/material'
import { useDispatch, useSelector} from 'react-redux';
import { setTempVehicle } from '../../../../features/temp/tempVehicleSlice';

function VehicleEditModalBody() {
    const dispatch = useDispatch();
    const { rowData, multiple } = useSelector(state => state.editPopup)
    const vid = rowData[0][0] !== undefined ? rowData[0][0] : rowData[0]
    const { id, fid, imei, capacity, capacity_type, cc, color, plate, provider, seats, vin, year } = useSelector(state => state.vehicles.vehicles.find(vehicle => vehicle.id === vid));
    useEffect(() => {
        if (!multiple) {
            dispatch(setTempVehicle({id, fid, imei, capacity, capacity_type, cc, color, plate, provider, seats, vin, year }))
        }else{
            dispatch(setTempVehicle({
                id: null,
                fid: null,
                imei: null,
                capacity: null,
                capacity_type: null,
                cc: null,
                color: null,
                plate: null,
                provider: null,
                seats: null,
                vin: null,
                year: null 
            }))
        }
    }, [multiple, dispatch, id, fid, imei, capacity, capacity_type, cc, color, plate, provider, seats, vin, year])

    const handleChange = (value, type) => {
        dispatch(setTempVehicle({[type]: value}));
    }
    const checkIfMultiple = (value) => {
        if (multiple) {
            return ''
        } else {
            return value !== undefined ? value : '' 
        }
    }
    const fuel_types = [
        {
            value: 1,
            label: 'Electric'
        },
        {
            value: 2,
            label: 'Diesel'
        },
        {
            value: 3,
            label: 'Unleaded'
        },
        {
            value: 4,
            label: 'Leaded'
        },
        {
            value: 5,
            label: 'Petrol'
        }
    ]
    const capacity_types = [
        {
            value: 'cc',
            label: 'CC'
        },
        {
            value: 'kwh',
            label: 'KWH'
        }
    ]
    const providers = [
        {
            value: 'bce',
            label: 'BCE'
        },
        {
            value: 'cello',
            label: 'CELLO'
        },
        {
            value: 'teltonika',
            label: 'TELTONIKA'
        },
        {
            value: 'ulu',
            label: 'ULU'
        },
        {
            value: 'bot',
            label: 'BOT'
        }
    ]
    return (
        <div className='modal-body'>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Fuel type'
                        select
                        defaultValue={checkIfMultiple(fid)}
                        onChange={(e) => handleChange(e.target.value, 'fid')}
                        fullWidth
                        style={{padding: '10px'}}
                    >
                        {fuel_types.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='IMEI'
                        defaultValue={checkIfMultiple(imei)}
                        onChange={(e) => handleChange(e.target.value, 'imei')}
                        fullWidth
                        type="number"
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Capacity'
                        defaultValue={checkIfMultiple(capacity)}
                        onChange={(e) => handleChange(e.target.value, 'capacity')}
                        fullWidth
                        type="number"
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Capacity Type'
                        select
                        value = {checkIfMultiple(capacity_type)}
                        onChange={(e) => handleChange(e.target.value, 'capacity_type')}
                        fullWidth
                        style={{padding: '10px'}}
                    >
                        {capacity_types.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='CC / KWH'
                        defaultValue={checkIfMultiple(cc)}
                        onChange={(e) => handleChange(e.target.value, 'cc')}
                        fullWidth
                        type="number"
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Color'
                        defaultValue={checkIfMultiple(color)}
                        onChange={(e) => handleChange(e.target.value, 'color')}
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Plate'
                        defaultValue={checkIfMultiple(plate)}
                        onChange={(e) => handleChange(e.target.value, 'plate')}
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Provider'
                        select
                        value={checkIfMultiple(provider)}
                        onChange={(e) => handleChange(e.target.value, 'provider')}
                        fullWidth
                        style={{padding: '10px'}}
                    >
                        {providers.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Seats'
                        type="number"
                        defaultValue={checkIfMultiple(seats)}
                        onChange={(e) => handleChange(e.target.value, 'seats')}
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='row w-100 mt-4'>
                    <div className='col-4'>
                        <TextField
                            label='VIN'
                            defaultValue={checkIfMultiple(vin)}
                            onChange={(e) => handleChange(e.target.value, 'vin')}
                            fullWidth
                            style={{padding: '10px'}}
                        />
                    </div>
                    <div className='col-4'>
                        <TextField
                            label='Year'
                            type="date"
                            defaultValue={checkIfMultiple(year)}
                            onChange={(e) => handleChange(e.target.value, 'year')}
                            fullWidth
                            style={{padding: '10px'}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleEditModalBody