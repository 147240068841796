import React, {useEffect} from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import moment from 'moment';
import { useDispatch, useSelector} from 'react-redux';
import { setCertificates } from '../../features/certificate/certificateSlice';
import { setDeletePopup } from '../../features/popup/deletePopupSlice';
import { setEditPopup } from '../../features/popup/editPopupSlice';
import { setError } from '../../features/error/errorSlice';
import TableButtons from '../general/buttons/TableButtons';
import DeletePopup from '../general/modal/DeletePopup';
import EditPopup from '../general/modal/EditPopup';
import AddNewButton from '../general/buttons/AddNewButton';
import MultiEditButton from '../general/buttons/MultiEditButton';

function CertificatesDashboard() {
    const dispatch = useDispatch();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/certificates`,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            dispatch(setCertificates( res.data ))
        })
        .catch(err => {
            if(err.response.status === 403){
                localStorage.removeItem('token')
                window.location.href = '/'
            }else{
                dispatch(setError({
                    isOpen:true, 
                    title: `Error ${err.response.status}`,
                    message: err.response.data.description
                }));
            }
        })
    }, [dispatch])
    const HeaderElements = () => (
        <AddNewButton />
    );
    const handleEdit = (rowData) => {
        dispatch(setEditPopup({
            datatable: 'certificate',
            isOpen: true,
            rowData: rowData
        }))
    }
    const handleDelete = (rowData) => {
        dispatch(setDeletePopup({
            datatable: 'certificate',
            isOpen: true,
            rowData: rowData
        }))
    }
    const columns = [
        {
            name: "id",
            label: "ID"
        },
        {
            name: "type",
            label: "Type"
        },
        {
            name: "name",
            label: "Name"
        },
        {
            name: "renewal_times",
            label: "Renewal Times"
        },
        {
            name: "date_renewed",
            label: "Date Renewed",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('MMMM Do YYYY HH:mm:ss')
                }
            }
        },
        {
            name: "due_date",
            label: "Due Date",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('MMM Do YYYY  HH:mm:ss')
                }
            }
        },
        {
            name: "functionality",
            label: "Functionality",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <TableButtons handleEdit={handleEdit} handleDelete={handleDelete} rowData={tableMeta.rowData} />
                    )
                },
                filter: false,
                sort: false
            }
        },
    ]
    const data = useSelector(state => state.certificates.certificates);
    const options = {
        responsive: 'standard',
        tableBodyHeight: '85vh',
        tableBodyMaxHeight: '85vh',
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 50, 100, 500],
        verticalAlign: 'middle',
        selectableRowsHeader: true,
        customToolbar: () => (
            <HeaderElements />
        ),
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            // Mock array is used in order to load the multi edit component with initial data
            // Last item, the edit/delete buttons, is removed from the array
            let indexes = selectedRows.data.map(row => row.dataIndex);
            // Remove last item from array
            let mockArray = []
            displayData.forEach(item => {
                if(indexes.includes(item.dataIndex)){
                    let newItem = [...item.data]
                    mockArray.push(newItem.splice(0, newItem.length - 1))
                }
            });
            return(
                <MultiEditButton rowData={mockArray} dt_name={'certificate'}/>
            )
        }
    }

    return (
        <div className='certificates-dashboard h-100 datatable'>
            <MUIDataTable
                title={"Certificates"}
                data={data}
                columns={columns}
                options={options}
            />
            {useSelector(state => state.deletePopup.isOpen) && <DeletePopup />}
            {useSelector(state => state.editPopup.isOpen) && <EditPopup />}
        </div>
    )
}

export default CertificatesDashboard