import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    datatable: '',
    isOpen: false
}
const addPopupSlice = createSlice({
    name: "addPopup",
    initialState: initialState,
    reducers: {
        setAddPopup: (state, action) => {
            state.datatable = action.payload.datatable;
            state.isOpen = action.payload.isOpen;
            state.rowData = action.payload.rowData;
        }
    }
});

export const { setAddPopup } = addPopupSlice.actions;
export default addPopupSlice.reducer;