import React from 'react'
import { Card, CardHeader, CardContent  } from '@mui/material';

function Uptime({ uptime }) {
    return (
        <Card sx={{ width: '100%' }} >
            <CardHeader title='Uptime' />
            <CardContent>
                <div className='text-center'>
                    <span className='uptime'>{uptime}</span>
                </div>
            </CardContent>
        </Card>
    )
}

export default Uptime