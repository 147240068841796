import React, {useEffect} from 'react'
import { TextField, MenuItem } from '@mui/material'
import { useDispatch, useSelector} from 'react-redux';
import { setTempCompany } from '../../../../features/temp/tempCompanySlice';

function CompanyEditModalBody() {
    const dispatch = useDispatch();
    const { rowData, multiple } = useSelector(state => state.editPopup)
    const cpid = rowData[0][0] !== undefined ? rowData[0][0] : rowData[0]
    const { id, name, alias, type, vat, tax_office, phone_code_prefix, phone, emergency_phone, address, postcode, city, country, currency, locales, latitude, longitude, cardlink, viva, viva_native, paypal, paymentexpress, gocardless, mass_cancellation, mass_lock, disable_immobilizer_if_reservation, unreserved_vehicle_alert, battery_level_alert, fuel_level_alert, lost_signal_alert, driver_license_expiration_alert, speed_limit_alert, partial_charge_reservation, active, access_token, firebase_server_key, android_version, ios_version, force_mobile_app_update, member_personal_required_fields, customer_billing_required_fields, send_invoice_to } = useSelector(state => state.companies.companies.find(company => company.id === cpid))
    
    useEffect(() => {
        if (!multiple) {
            dispatch(setTempCompany({ id, name, alias, type, vat, tax_office, phone_code_prefix, phone, emergency_phone, address, postcode, city, country, currency, locales, latitude, longitude, cardlink, viva, viva_native, paypal, paymentexpress, gocardless, mass_cancellation, mass_lock, disable_immobilizer_if_reservation, unreserved_vehicle_alert, battery_level_alert, fuel_level_alert, lost_signal_alert, driver_license_expiration_alert, speed_limit_alert, partial_charge_reservation, active, access_token, firebase_server_key, android_version, ios_version, force_mobile_app_update, member_personal_required_fields, customer_billing_required_fields, send_invoice_to }))
        }else{
            dispatch(setTempCompany({
                id: null,
                name: null,
                alias: null,
                type: null,
                vat: null,
                tax_office: null,
                phone_code_prefix: null,
                phone: null,
                emergency_phone: null,
                address: null,
                postcode: null,
                city: null,
                country: null,
                currency: null,
                locales: null,
                latitude: null,
                longitude: null,
                cardlink: null,
                viva: null,
                viva_native: null,
                paypal: null,
                paymentexpress: null,
                gocardless: null,
                mass_cancellation: null,
                mass_lock: null,
                disable_immobilizer_if_reservation: null,
                unreserved_vehicle_alert: null,
                battery_level_alert: null,
                fuel_level_alert: null,
                lost_signal_alert: null,
                driver_license_expiration_alert: null,
                speed_limit_alert: null,
                partial_charge_reservation: null,
                active: null,
                access_token: null,
                firebase_server_key: null,
                android_version: null,
                ios_version: null,
                force_mobile_app_update: null,
                member_personal_required_fields: null,
                customer_billing_required_fields: null,
                send_invoice_to: null
            }))
        }
    }, [multiple, dispatch, id, name, alias, type, vat, tax_office, phone_code_prefix, phone, emergency_phone, address, postcode, city, country, currency, locales, latitude, longitude, cardlink, viva, viva_native, paypal, paymentexpress, gocardless, mass_cancellation, mass_lock, disable_immobilizer_if_reservation, unreserved_vehicle_alert, battery_level_alert, fuel_level_alert, lost_signal_alert, driver_license_expiration_alert, speed_limit_alert, partial_charge_reservation, active, access_token, firebase_server_key, android_version, ios_version, force_mobile_app_update, member_personal_required_fields, customer_billing_required_fields, send_invoice_to])
    const handleChange = (value, type) => {
        dispatch(setTempCompany({[type]: value}));
    }
    const checkIfMultiple = (value) => {
        if (multiple) {
            return ''
        } else {
            return value !== undefined ? value : '' 
        }
    }
    const companyTypes = [
        {
            value: 'Telematics',
            label: 'Telematics'
        },
        {
            value: 'CarSharing',
            label: 'CarSharing'
        }
    ]
    return (
        <div className='modal-body'>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Name'
                        defaultValue={checkIfMultiple(name)}
                        onChange={(e) => handleChange(e.target.value, 'name')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Alias'
                        defaultValue={checkIfMultiple(alias)}
                        onChange={(e) => handleChange(e.target.value, 'alias')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Type'
                        select
                        onChange={(e) => handleChange(e.target.value, 'type')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        defaultValue={companyTypes.includes(type) ? checkIfMultiple(type) : 'Telematics'}
                    >
                        {companyTypes.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='VAT'
                        defaultValue={checkIfMultiple(vat)}
                        onChange={(e) => handleChange(e.target.value, 'vat')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Tax Office'
                        defaultValue={checkIfMultiple(tax_office)}
                        onChange={(e) => handleChange(e.target.value, 'tax_office')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Phone Code Prefix'
                        type={'number'}
                        defaultValue={checkIfMultiple(phone_code_prefix)}
                        onChange={(e) => handleChange(e.target.value, 'phone_code_prefix')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Phone'
                        defaultValue={checkIfMultiple( phone.split('-')[1] )}
                        type={'number'}
                        onChange={(e) => handleChange(e.target.value, 'phone')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Emergency Phone'
                        defaultValue={checkIfMultiple( emergency_phone ? emergency_phone.replace(`+${phone_code_prefix}`, ''): '' )}
                        type={'number'}
                        onChange={(e) => handleChange(e.target.value, 'emergency_phone')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Address'
                        defaultValue={checkIfMultiple(address)}
                        onChange={(e) => handleChange(e.target.value, 'address')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Postcode'
                        type={'number'}
                        defaultValue={checkIfMultiple(postcode)}
                        onChange={(e) => handleChange(e.target.value, 'postcode')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='City'
                        defaultValue={checkIfMultiple(city)}
                        onChange={(e) => handleChange(e.target.value, 'city')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Country'
                        defaultValue={checkIfMultiple(country)}
                        onChange={(e) => handleChange(e.target.value, 'country')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Currency'
                        defaultValue={checkIfMultiple(currency)}
                        onChange={(e) => handleChange(e.target.value, 'currency')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Locales'
                        defaultValue={checkIfMultiple(locales)}
                        onChange={(e) => handleChange(e.target.value, 'locales')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Access Token'
                        defaultValue={checkIfMultiple(access_token)}
                        onChange={(e) => handleChange(e.target.value, 'access_token')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-6'>
                    <TextField
                        label='Latitude'
                        type={'number'}
                        defaultValue={checkIfMultiple(latitude)}
                        onChange={(e) => handleChange(e.target.value, 'latitude')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-6'>
                    <TextField
                        label='Longitude'
                        type={'number'}
                        defaultValue={checkIfMultiple(longitude)}
                        onChange={(e) => handleChange(e.target.value, 'longitude')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-12'>
                    <TextField
                        label='Firebase Key'
                        defaultValue={checkIfMultiple(firebase_server_key)}
                        onChange={(e) => handleChange(e.target.value, 'firebase_server_key')}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='CardLink'
                        defaultValue={checkIfMultiple(cardlink)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'cardlink')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Viva'
                        defaultValue={checkIfMultiple(viva)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'viva')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Viva Native'
                        defaultValue={checkIfMultiple(viva_native)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'viva_native')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='PayPal'
                        defaultValue={checkIfMultiple(paypal)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'paypal')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='PaymentExpress'
                        defaultValue={checkIfMultiple(paymentexpress)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'paymentexpress')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='GoCardless'
                        defaultValue={checkIfMultiple(gocardless)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'gocardless')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Mass Cancel'
                        defaultValue={checkIfMultiple(mass_cancellation)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'mass_cancel')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Mass Lock'
                        defaultValue={checkIfMultiple(mass_lock)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'mass_lock')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Disable Immobilizer'
                        defaultValue={checkIfMultiple(disable_immobilizer_if_reservation)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'disable_immobilizer_if_reservation')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Unreserved Vehicle Alert'
                        defaultValue={checkIfMultiple(unreserved_vehicle_alert)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'unreserved_vehicle_alert')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Battery Level Alert'
                        defaultValue={checkIfMultiple(battery_level_alert)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'battery_level_alert')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Fuel Level Alert'
                        defaultValue={checkIfMultiple(fuel_level_alert)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'fuel_level_alert')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Lost Signal Alert'
                        defaultValue={checkIfMultiple(lost_signal_alert)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'lost_signal_alert')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Driver License Expiry Alert'
                        defaultValue={checkIfMultiple(driver_license_expiration_alert)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'driver_license_expiration_alert')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Speed Limit Alert'
                        defaultValue={checkIfMultiple(speed_limit_alert)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'speed_limit_alert')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-6'>
                    <TextField
                        label='Partial Charge Reservation'
                        defaultValue={checkIfMultiple(partial_charge_reservation)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'partial_charge_reservation')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
                <div className='col-6'>
                    <TextField
                        label='Active'
                        defaultValue={checkIfMultiple(active)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'active')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Android App Version'
                        defaultValue={checkIfMultiple(android_version)}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'android_version')}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='iOS App Version'
                        defaultValue={checkIfMultiple(ios_version)}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'ios_version')}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Force Mobile App Update'
                        defaultValue={checkIfMultiple(force_mobile_app_update)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'force_mobile_app_update')}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </TextField>
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-12'>
                    <TextField
                        label='Member Required Fields'
                        defaultValue={checkIfMultiple(member_personal_required_fields)}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'member_personal_required_fields')}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-12'>
                    <TextField
                        label='Customer Billing Fields'
                        defaultValue={checkIfMultiple(customer_billing_required_fields)}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'member_personal_required_fields')}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'></div>
                <div className='col-4'>
                    <TextField
                        label='Send Invoice To'
                        defaultValue={checkIfMultiple(send_invoice_to)}
                        select
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        style={{padding: '10px'}}
                        onChange={(e) => handleChange(e.target.value, 'send_invoice_to')}
                    >
                        <MenuItem value={'member'}>Member</MenuItem>
                        <MenuItem value={'none'}>None</MenuItem>
                    </TextField>
                </div>
                <div className='col-4'></div>
            </div>
        </div>
    )
}

export default CompanyEditModalBody