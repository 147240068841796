import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    name: null,
    alias: null,
    type: null,
    vat: null,
    tax_office: null,
    phone_code_prefix: null,
    phone: null,
    emergency_phone: null,
    address: null,
    postcode: null,
    city: null,
    country: null,
    currency: null,
    locales: null,
    latitude: null,
    longitude: null,
    cardlink: 0,
    viva: 0,
    viva_native: 0,
    paypal: 0,
    paymentexpress: 0,
    gocardless: 0,
    mass_cancellation: 0,
    mass_lock: 0,
    disable_immobilizer_if_reservation: 0,
    unreserved_vehicle_alert: 0,
    battery_level_alert: 0,
    fuel_level_alert: 0,
    lost_signal_alert: 0,
    driver_license_expiration_alert: 0,
    speed_limit_alert: 0,
    partial_charge_reservation: 0,
    active: 0,
    access_token: null,
    firebase_server_key: null,
    android_version: null,
    ios_version: null,
    force_mobile_app_update: 0,
    member_personal_required_fields: null,
    customer_billing_required_fields: null,
    send_invoice_to: null,
}
const tempCompanySlice = createSlice({
    name: "tempCompany",
    initialState: initialState,
    reducers: {
        setTempCompany: (state, action) => {
            state.id = action.payload.id || state.id;
            state.name = action.payload.name || state.name;
            state.alias = action.payload.alias || state.alias;
            state.type = action.payload.type || state.type;
            state.vat = action.payload.vat || state.vat;
            state.tax_office = action.payload.tax_office || state.tax_office;
            state.phone_code_prefix = action.payload.phone_code_prefix || state.phone_code_prefix;
            state.phone = action.payload.phone || state.phone;
            state.emergency_phone = action.payload.emergency_phone || state.emergency_phone;
            state.address = action.payload.address || state.address;
            state.postcode = action.payload.postcode || state.postcode;
            state.city = action.payload.city || state.city;
            state.country = action.payload.country || state.country;
            state.currency = action.payload.currency || state.currency;
            state.locales = action.payload.locales || state.locales;
            state.latitude = action.payload.latitude || state.latitude;
            state.longitude = action.payload.longitude || state.longitude;
            state.cardlink = action.payload.cardlink || state.cardlink;
            state.viva = action.payload.viva || state.viva;
            state.viva_native = action.payload.viva_native || state.viva_native;
            state.paypal = action.payload.paypal || state.paypal;
            state.paymentexpress = action.payload.paymentexpress || state.paymentexpress;
            state.gocardless = action.payload.gocardless || state.gocardless;
            state.mass_cancellation = action.payload.mass_cancellation || state.mass_cancellation;
            state.mass_lock = action.payload.mass_lock || state.mass_lock;
            state.disable_immobilizer_if_reservation = action.payload.disable_immobilizer_if_reservation || state.disable_immobilizer_if_reservation;
            state.unreserved_vehicle_alert = action.payload.unreserved_vehicle_alert || state.unreserved_vehicle_alert;
            state.battery_level_alert = action.payload.battery_level_alert || state.battery_level_alert;
            state.fuel_level_alert = action.payload.fuel_level_alert || state.fuel_level_alert;
            state.lost_signal_alert = action.payload.lost_signal_alert || state.lost_signal_alert;
            state.driver_license_expiration_alert = action.payload.driver_license_expiration_alert || state.driver_license_expiration_alert;
            state.speed_limit_alert = action.payload.speed_limit_alert || state.speed_limit_alert;
            state.partial_charge_reservation = action.payload.partial_charge_reservation || state.partial_charge_reservation;
            state.active = action.payload.active || state.active;
            state.access_token = action.payload.access_token || state.access_token;
            state.firebase_server_key = action.payload.firebase_server_key || state.firebase_server_key;
            state.android_version = action.payload.android_version || state.android_version;
            state.ios_version = action.payload.ios_version || state.ios_version;
            state.force_mobile_app_update = action.payload.force_mobile_app_update || state.force_mobile_app_update;
            state.member_personal_required_fields = action.payload.member_personal_required_fields || state.member_personal_required_fields;
            state.customer_billing_required_fields = action.payload.customer_billing_required_fields || state.customer_billing_required_fields;
            state.send_invoice_to = action.payload.send_invoice_to || state.send_invoice_to;
        },
        clearTempCompany: (state, action) => {
            state.id = null;
            state.name = null;
            state.alias = null;
            state.type = null;
            state.vat = null;
            state.tax_office = null;
            state.phone_code_prefix = null;
            state.phone = null;
            state.emergency_phone = null;
            state.address = null;
            state.postcode = null;
            state.city = null;
            state.country = null;
            state.currency = null;
            state.locales = null;
            state.latitude = null;
            state.longitude = null;
            state.cardlink = null;
            state.viva = null;
            state.viva_native = null;
            state.paypal = null;
            state.paymentexpress = null;
            state.gocardless = null;
            state.mass_cancellation = null;
            state.mass_lock = null;
            state.disable_immobilizer_if_reservation = null;
            state.unreserved_vehicle_alert = null;
            state.battery_level_alert = null;
            state.fuel_level_alert = null;
            state.lost_signal_alert = null;
            state.driver_license_expiration_alert = null;
            state.speed_limit_alert = null;
            state.partial_charge_reservation = null;
            state.active = null;
            state.access_token = null;
            state.firebase_server_key = null;
            state.android_version = null;
            state.ios_version = null;
            state.force_mobile_app_update = null;
            state.member_personal_required_fields = null;
            state.customer_billing_required_fields = null;
            state.send_invoice_to = null;
        }
    }
});

export const { setTempCompany, clearTempCompany } = tempCompanySlice.actions;
export default tempCompanySlice.reducer;
