import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSelected } from '../../features/sidebar/sidebarSlice';
import moment from 'moment';

function HomeDashboard() {
    const dispatch = useDispatch();
    const { dashboard } = useSelector(state => state.dashboard);
    const handleClick = (selected) => {
        dispatch(setSelected(selected));
        const homeBtn = document.querySelector('#home');
        const selectedBtn = document.querySelector(`#${selected}`);
        homeBtn.classList.remove('active');
        selectedBtn.classList.add('active');
    }
    return (
        <div className='home-dashboard'>
            <h1 className='dashboard-title'>Home Dashboard</h1>
            <div className='row h-100 mt-3'>
                <div className='col'>
                    <div className="card" onClick={()=> handleClick('users')}>
                        <div className="card-header">
                            <h3 className='card-title'>Users</h3>
                        </div>
                        <div className="card-body">
                            <div className="text-center mt-2"><p>Total Users</p><span>{dashboard.total_users}</span></div>
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className="card" onClick={()=> handleClick('vehicles')}>
                        <div className="card-header">
                            <h3 className='card-title'>Vehicles</h3>
                        </div>
                        <div className="card-body">
                            <div className="text-center mt-2"><p>Total Vehicles</p><span>{dashboard.total_vehicles}</span></div>
                            <div className="text-center mt-2"><p>Last Updated</p><span>{moment(new Date(dashboard.vehicles_last_updated)).format('MMMM Do YYYY, h:mm:ss')}</span></div>
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className="card" onClick={()=> handleClick('sims')}>
                        <div className="card-header">
                            <h3 className='card-title'>Sims</h3>
                        </div>
                        <div className="card-body">
                            <div className="text-center mt-2"><p>Total Sims</p><span>{dashboard.total_sims}</span></div>
                            <div className="text-center mt-2"><p>To be renewed</p><span>{moment(new Date(dashboard.sims_renewal_date)).format('MMMM Do YYYY, h:mm:ss')}</span></div>
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className="card" onClick={()=> handleClick('certificates')}>
                        <div className="card-header">
                            <h3 className='card-title'>Certificates</h3>
                        </div>
                        <div className="card-body">
                            <div className="text-center mt-2"><p>Total Certificates</p><span>{dashboard.total_certificates}</span></div>
                            <div className="text-center mt-2"><p>To be renewed</p><span>{moment(new Date(dashboard.certificates_renewal_date)).format('MMMM Do YYYY, h:mm:ss')}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeDashboard