import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    user: null,
    activation_date: null,
    active: true,
    imei: null,
    provider: null,
    phone_number: null,
    plate: null,
    price: null,
    renewal_date: null,
    sim_vin: null,
    type: null
}

const tempSimSlice = createSlice({
    name: "tempSim",
    initialState: initialState,
    reducers: {
        setTempSim: (state, action) => {
            state.id = parseInt(action.payload.id) || state.id;
            state.user = action.payload.user || state.user;
            state.activation_date = action.payload.activation_date || state.activation_date;
            state.active = action.payload.active !== undefined ? action.payload.active : state.active;
            state.imei = parseInt(action.payload.imei) || state.imei;
            state.provider = action.payload.provider || state.provider;
            state.phone_number = parseInt(action.payload.phone_number) || state.phone_number;
            state.plate = action.payload.plate || state.plate;
            state.price = parseInt(action.payload.price) || state.price;
            state.renewal_date = action.payload.renewal_date || state.renewal_date;
            state.sim_vin = action.payload.sim_vin || state.sim_vin;
            state.type = action.payload.type || state.type;
        },
        clearTempSim: (state, action) => {
            state.id = null;
            state.user = null;
            state.activation_date = null;
            state.active = true;
            state.imei = null;
            state.provider = null;
            state.phone_number = null;
            state.plate = null;
            state.price = null;
            state.renewal_date = null;
            state.sim_vin = null;
            state.type = null;
        }
    }
});

export const { setTempSim, clearTempSim } = tempSimSlice.actions;
export default tempSimSlice.reducer;