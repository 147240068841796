import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { setAddPopup } from '../../../features/popup/addPopupSlice';
import AddPopup from '../modal/AddPopup';

function AddNewButton({dt_name}) {
    const dispatch = useDispatch();
    const addNewElement = () => {
        dispatch(setAddPopup({
            datatable: dt_name,
            isOpen: true
        }))
    }
    return (
        <>
            <Tooltip title='Add new element'>
                <Fab style={{zIndex: 1}} size='small' color='primary' onClick={()=>addNewElement()}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            {useSelector(state => state.addPopup.isOpen) && <AddPopup />}
        </>
    )
}

export default AddNewButton