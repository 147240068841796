import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    datatable: '',
    isOpen: false,
    multiple: false,
    rowData: null
}
const editPopupSlice = createSlice({
    name: "editPopup",
    initialState: initialState,
    reducers: {
        setEditPopup: (state, action) => {
            state.datatable = action.payload.datatable;
            state.isOpen = action.payload.isOpen;
            state.rowData = action.payload.rowData;
            state.multiple = action.payload.multiple;
        }
    }
});

export const { setEditPopup } = editPopupSlice.actions;
export default editPopupSlice.reducer;