import React, {useEffect} from 'react'
import { TextField, MenuItem } from '@mui/material'
import { useDispatch, useSelector} from 'react-redux';
import { setTempSim } from '../../../../features/temp/tempSimSlice';

function SimEditModalBody(){
    const dispatch = useDispatch();
    const { rowData, multiple } = useSelector(state => state.editPopup)
    const sid = rowData[0][0] !== undefined ? rowData[0][0] : rowData[0]
    const {id, user, activation_date, active, imei, provider, phone_number, plate, price, renewal_date, sim_vin, type } = useSelector(state => state.sims.sims.find(sim => sim.id === sid));
    useEffect(() => {
        if (!multiple) {
            dispatch(setTempSim({id, user, activation_date, active, imei, provider, phone_number, plate, price, renewal_date, sim_vin, type }))
        }else{
            dispatch(setTempSim({
                id: null,
                user: null,
                activation_date: null,
                active: null,
                imei: null,
                provider: null,
                phone_number: null,
                plate: null,
                price: null,
                renewal_date: null,
                sim_vin: null,
                type: null 
            }))
        }
    }, [multiple, dispatch, id, user, activation_date, active, imei, provider, phone_number, plate, price, renewal_date, sim_vin, type])
    const handleChange = (value, type) => {
        dispatch(setTempSim({[type]: value}));
    }
    const checkIfMultiple = (value) => {
        if (multiple) {
            return ''
        } else {
            return value !== undefined ? value : '' 
        }
    }
    const active_values = [
        {
            value: true,
            label: 'Active'
        },
        {
            value: false,
            label: 'Inactive'
        }
    ]
    const provider_values = [
        {
            value: 'BCE',
            label: 'BCE'
        },
        {
            value: 'CELLO',
            label: 'CELLO'
        },
        {
            value: 'TELTONIKA',
            label: 'TELTONIKA'
        },
        {
            value: 'ULU',
            label: 'ULU'
        }
    ]
    return (
        <div className='modal-body'>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='User'
                        defaultValue={ checkIfMultiple(user) }
                        onChange={(e)=> handleChange(e.target.value, 'user')}
                        helperText="Client's name/ alias"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Activation Date'
                        type={'date'}
                        defaultValue={ checkIfMultiple(activation_date) }
                        onChange={(e)=> handleChange(e.target.value, 'activation_date')}
                        helperText="Date of activation"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Active'
                        select
                        defaultValue={ checkIfMultiple(active) }
                        onChange={(e)=> handleChange(e.target.value, 'active')}
                        helperText="Sim is active or not"
                        fullWidth
                        style={{padding: '10px'}}
                    >
                        {active_values.map(option => {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='IMEI'
                        type={'number'}
                        defaultValue={ checkIfMultiple(imei) }
                        onChange={(e)=> handleChange(e.target.value, 'imei')}
                        helperText="IMEI number of the device"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Provider'
                        select
                        defaultValue={ checkIfMultiple(provider) }
                        onChange={(e)=> handleChange(e.target.value, 'provider')}
                        helperText="Provider of the sim"
                        fullWidth
                        style={{padding: '10px'}}
                    >
                        {provider_values.map(option => {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Phone Number'
                        type={'number'}
                        defaultValue={ checkIfMultiple(phone_number) }
                        onChange={(e)=> handleChange(e.target.value, 'phone_number')}
                        helperText="Phone number of the device"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Plate'
                        defaultValue={ checkIfMultiple(plate) }
                        onChange={(e)=> handleChange(e.target.value, 'plate')}
                        helperText="Plate number of the vehicle"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Price'
                        type={'number'}
                        defaultValue={ checkIfMultiple(price) }
                        onChange={(e)=> handleChange(e.target.value, 'price')}
                        helperText="Price of the sim"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Renewal Date'
                        type={'date'}
                        defaultValue={ checkIfMultiple(renewal_date) }
                        onChange={(e)=> handleChange(e.target.value, 'renewal_date')}
                        helperText="Date of renewal"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='VIN'
                        defaultValue={ checkIfMultiple(sim_vin) }
                        onChange={(e)=> handleChange(e.target.value, 'sim_vin')}
                        helperText="VIN number of the vehicle"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Type'
                        defaultValue={ checkIfMultiple(type) }
                        onChange={(e)=> handleChange(e.target.value, 'type')}
                        helperText="Vehicle type/ model"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
        </div>
    )
}

export default SimEditModalBody