import React from 'react'
import { Card, CardHeader, CardContent, List, ListItem, ListItemText } from '@mui/material';
import moment from 'moment';

function MysqlStatus({ mysql_status }) {
    return (
        <Card sx={{ width: '100%', height: '100%' }} >
            <CardHeader title='MySQL Status' />
            <CardContent>
                <List>
                    <ListItem>
                        <ListItemText>
                            <span><b>Connections</b></span>
                        </ListItemText>
                        <ListItemText>
                            <span style={{ float: 'right' }}>{mysql_status.connections}</span>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <span><b>Queries</b></span>
                        </ListItemText>
                        <ListItemText>
                            <span style={{ float: 'right' }}>{mysql_status.queries}</span>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <span><b>Slow Queries</b></span>
                        </ListItemText>
                        <ListItemText>
                            <span style={{ float: 'right' }}>{mysql_status.slow_queries}</span>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <span><b>Syncs</b></span>
                        </ListItemText>
                        <ListItemText>
                            <span style={{ float: 'right' }}>{mysql_status.syncs}</span>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <span><b>Threads Connected</b></span>
                        </ListItemText>
                        <ListItemText>
                            <span style={{ float: 'right' }}>{mysql_status.threads_connected}</span>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <span><b>Threads Running</b></span>
                        </ListItemText>
                        <ListItemText>
                            <span style={{ float: 'right' }}>{mysql_status.threads_running}</span>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <span><b>Uptime</b></span>
                        </ListItemText>
                        <ListItemText>
                            <span style={{ float: 'right' }}>{moment(mysql_status.uptime).format('HH:mm:ss') }</span>
                        </ListItemText>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    )
}

export default MysqlStatus