import './styles/app.scss';
import Login from './components/Login';
import Home from './components/Home';

function App() {
  return (
    <>
      {/* TODO: This doesnt always work */}
      {/* If user is not authenticated */}
      {!localStorage.getItem('token') && <Login />}
      {/* If user is authenticated */}
      {localStorage.getItem('token') && <Home />}
    </>
  );
}

export default App;
