import React from 'react'
import { useState } from 'react';
import Error from './general/Error';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '../features/error/errorSlice';
import axios from 'axios';

function Login() {
    const dispatch = useDispatch();
    const errorMsg = useSelector(state => state.error.message);
    // TODO: Change this to use the redux store
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/authenticate`, {
            email: email,
            password: password
        }).then(res => {
            if (res.data.success === false){
                dispatch(setError({
                    isOpen:true, 
                    message: res.data.description
                }));
            }
            const { token } = res.data.results;
            localStorage.setItem('token', token);
            window.location.href = '/';
        }).catch(err => {
            if(err.response.status === 403){
                localStorage.removeItem('token')
                window.location.href = '/'
            }else{
                dispatch(setError({
                    isOpen:true, 
                    title: `Error ${err.response.status}`,
                    message: err.response.data.description
                }));
            }
        });
    }
    return (
        <>
            { errorMsg && <Error /> }
            <div className='login-container'>
                <div className='login-form'>
                    <img className="login-image" src="images/login_logo.png" alt="Login logo" />
                    <input type="text" name="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input type="password" name="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    <br />
                    <button onClick={login}>Login</button>
                </div>
            </div>
        </>
    )
}

export default Login