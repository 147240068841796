import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selected: 'home'
}
const sidebarSlice = createSlice({
    name: "sidebar",
    initialState: initialState,
    reducers: {
        setSelected: (state, action) => {
            state.selected = action.payload;
        }
    }
});

export const { setSelected } = sidebarSlice.actions;
export default sidebarSlice.reducer;