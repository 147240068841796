import React, {useEffect} from 'react'
import { TextField, MenuItem } from '@mui/material'
import { useDispatch, useSelector} from 'react-redux';
import { setTempUser } from '../../../../features/temp/tempUserSlice';

function UserEditModalBody() {
    const dispatch = useDispatch();
    const { rowData, multiple } = useSelector(state => state.editPopup)
    const uid = rowData[0][0] !== undefined ? rowData[0][0] : rowData[0]
    const { id, validated, gdpr_compliant, email } = useSelector(state => state.users.users.find(user => user.id === uid))
    useEffect(() => {
        if(!multiple) {
            dispatch(setTempUser({id, validated, gdpr_compliant, email }))
        }else{
            dispatch(setTempUser({id: null, validated: false, gdpr_compliant: false, email: null, password: null }))
        }
    }, [multiple, dispatch, id, validated, gdpr_compliant, email])
    const validatedValues = [
        {
            value: true,
            label: 'Yes'
        },
        {
            value: false,
            label: 'No'
        }
    ]
    const gdprValues = [
        {
            value: true,
            label: 'Yes'
        },
        {
            value: false,
            label: 'No'
        }
    ]
    const handleChange = (value, type) => {
        dispatch(setTempUser({[type]: value}));
    }
    const checkIfMultiple = (value) => {
        if (multiple) {
            return ''
        } else {
            return value !== undefined ? value : '' 
        }
    }
    return (
        <div className='modal-body'>
            <div className='row w-100 justify-evenly mt-4'>
                <TextField
                    className='col-3'
                    label='Validated'
                    select
                    defaultValue={checkIfMultiple(validated)}
                    onChange={(e)=> handleChange(e.target.value, 'validated')}
                    helperText="User is validated"
                >
                    {validatedValues.map(option => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <TextField
                    className='col-3'
                    label='GDPR Compliant'
                    select
                    defaultValue={checkIfMultiple(gdpr_compliant) }
                    onChange={(e)=> handleChange(e.target.value, 'gdpr_compliant')}
                    helperText="User is GDPR Compliant"
                >
                    {gdprValues.map(option => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        )
                    })}
                </TextField>
            </div>
            <div className='row w-100 mt-4'>
                <TextField
                    required
                    className='col-6'
                    label='Email'
                    type='email'
                    defaultValue={checkIfMultiple(email)}
                    helperText="User's email"
                    onChange={(e)=> handleChange(e.target.value, 'email')}
                />
            </div>
        </div>
    )
}

export default UserEditModalBody