import React from 'react'
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function TableButtons({ rowData, handleEdit, handleDelete }) {
    return (
        <div className='row justify-evenly w-max'>
            <div>
                <Button style={{marginLeft: '5px', marginRight: '5px'}} variant="outlined" startIcon={<EditIcon />} onClick={()=>handleEdit( rowData )}> Edit </Button>
            </div>
            <div>
                <Button style={{marginLeft: '5px', marginRight: '5px'}} variant="outlined" startIcon={<DeleteIcon />} onClick={()=>handleDelete( rowData )}> Delete </Button>
            </div>
        </div>
    )
}

export default TableButtons