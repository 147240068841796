import React, {useEffect} from 'react'
import moment from 'moment';
import { TextField, MenuItem } from '@mui/material'
import { useDispatch, useSelector} from 'react-redux';
import { setTempCertificate } from '../../../../features/temp/tempCertificateSlice';

function CertificateEditModalBody() {
    const dispatch = useDispatch();
    const { rowData, multiple } = useSelector(state => state.editPopup)
    const cid = rowData[0][0] !== undefined ? rowData[0][0] : rowData[0]
    const { id, type, name, renewal_times,due_date,date_renewed } = useSelector(state => state.certificates.certificates.find(certificate => certificate.id === cid))
    useEffect(() => {
        if (!multiple) {
            dispatch(setTempCertificate({id, type, name, renewal_times, due_date, date_renewed }))
        }else{
            dispatch(setTempCertificate({
                id: null,
                type: null,
                name: null,
                renewal_times: null,
                due_date: null,
                date_renewed: null
            }))
        }
    }, [multiple, dispatch, id, type, name, renewal_times, due_date, date_renewed])
    const typedValues = [
        {
            value: 'certificate',
            label: 'Certificate'
        },
        {
            value: 'domain',
            label: 'Domain'
        }
    ]
    const checkIfMultiple = (value) => {
        if (multiple) {
            return ''
        } else {
            return value !== undefined ? value : '' 
        }
    }
    const handleChange = (value, type) => {
        dispatch(setTempCertificate({[type]: value}));
    }
    return (
        <div className='modal-body'>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Type'
                        select
                        defaultValue={checkIfMultiple(type)}
                        onChange={(e)=> handleChange(e.target.value, 'type')}
                        helperText="Certificate"
                        fullWidth
                        style={{padding: '10px'}}
                    >
                        {typedValues.map(option => {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </div>
            
                <div className='col-4'>
                    <TextField
                        required
                        label='Name'
                        defaultValue= {checkIfMultiple(name)}
                        helperText="Certificate's name"
                        onChange={(e)=> handleChange(e.target.value, 'name')}
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        required
                        label='Renewal times'
                        type='number'
                        defaultValue={checkIfMultiple(renewal_times)}
                        helperText="How many times has it been renewed"
                        onChange={(e)=> handleChange(e.target.value, 'renewal_times')}
                        style={{padding: '10px'}}
                        fullWidth
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        type="datetime-local"
                        defaultValue={checkIfMultiple(moment(new Date(due_date)).format('YYYY-MM-DD'))}
                        onChange={(e)=> handleChange(e.target.value, 'due_date')}
                        helperText="Due Date"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        type="datetime-local"
                        defaultValue={checkIfMultiple(moment(new Date(date_renewed)).format('YYYY-MM-DD'))}
                        onChange={(e)=> handleChange(e.target.value, 'date_renewed')}
                        helperText="Date renewed"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
        </div>
    )
}

export default CertificateEditModalBody