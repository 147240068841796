import React, {useEffect} from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import moment from 'moment';
import { useDispatch, useSelector} from 'react-redux';
import { setCompanies } from '../../features/company/companySlice';
import { setDeletePopup } from '../../features/popup/deletePopupSlice';
import { setEditPopup } from '../../features/popup/editPopupSlice';
import { setError } from '../../features/error/errorSlice';
import TableButtons from '../general/buttons/TableButtons';
import DeletePopup from '../general/modal/DeletePopup';
import EditPopup from '../general/modal/EditPopup';
import AddNewButton from '../general/buttons/AddNewButton';
import MultiEditButton from '../general/buttons/MultiEditButton';

function CompaniesDashboard() {
    const dispatch = useDispatch();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/companies`,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            dispatch(setCompanies( res.data ))
        })
        .catch(err => {
            if(err.response.status === 403){
                localStorage.removeItem('token')
                window.location.href = '/'
            }else{
                dispatch(setError({
                    isOpen:true, 
                    title: `Error ${err.response.status}`,
                    message: err.response.data.description
                }));
            }
        })
    }, [dispatch])
    const HeaderElements = (rowData) => (
        <AddNewButton dt_name={'company'} />
    );
    const handleEdit = (rowData) => {
        dispatch(setEditPopup({
            datatable: 'company',
            isOpen: true,
            rowData: rowData
        }))
    }
    const handleDelete = (rowData) => {
        dispatch(setDeletePopup({
            datatable: 'company',
            isOpen: true,
            rowData: rowData
        }))
    }
    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: false,
            }
        },
        {
            name: 'name',
            label: 'Name',
            options:{
                filter: false
            }
        },
        {
            name: 'alias',
            label: 'Alias',
            options:{
                filter: false
            }
        },
        {
            name: 'type',
            label: 'Type',
        },
        { 
            name: 'vat',
            label: 'VAT',
            options:{
                filter: false,
                sort: false
            }
        },
        {
            name: 'tax_office',
            label: 'Tax Office',
        },
        {
            name: 'phone_code_prefix',
            label: 'Phone Code Prefix',
        },
        {
            name: 'phone',
            label: 'Phone',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'emergency_phone',
            label: 'Emergency Phone',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'address',
            label: 'Address',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'postcode',
            label: 'Postcode',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'city',
            label: 'City',
            options: {
                filter: false,
            }
        },
        {
            name: 'country',
            label: 'Country',
        },
        {
            name: 'currency',
            label: 'Currency',
        },
        {
            name: 'locales',
            label: 'Locales',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'latitude',
            label: 'Latitude',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'longitude',
            label: 'Longitude',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'cardlink',
            label: 'CardLink',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'viva',
            label: 'Viva',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'viva_native',
            label: 'Viva Native',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'paypal',
            label: 'PayPal',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'paymentexpress',
            label: 'PaymentExpress',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'gocardless',
            label: 'Gocardless',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'mass_cancellation',
            label: 'Mass Cancellation',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'mass_lock',
            label: 'Mass Lock',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'disable_immobilizer_if_reservation',
            label: 'DIR',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'unreserved_vehicle_alert',
            label: 'Unreserved Vehicle Alert',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'battery_level_alert',
            label: 'Battery Level Alert',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'fuel_level_alert',
            label: 'Fuel Level Alert',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'lost_signal_alert',
            label: 'Lost Signal Alert',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'driver_license_expiration_alert',
            label: 'Driver License Expiration Alert',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'speed_limit_alert',
            label: 'Speed Limit Alert',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'partial_charge_reservation',
            label: 'Partial Charge Reservation',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'active',
            label: 'Active',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'access_token',
            label: 'Access Token',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'firebase_server_key',
            label: 'Firebase Key',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'android_version',
            label: 'Android Version',
        },
        {
            name: 'ios_version',
            label: 'iOS Version',
        },
        {
            name: 'force_mobile_app_update',
            label: 'Force Mobile App Update',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return parseInt(value) === 1 ? 'Yes' : 'No'
                }
            }
        },
        {
            name: 'member_personal_required_fields',
            label: 'Member Required Fields',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'customer_billing_required_fields',
            label: 'Customer Required Fields',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'send_invoice_to',
            label: 'Send Invoice To',
        },
        {
            name: 'created',
            label: 'Created',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('DD-MM-YYYY HH:mm:ss')
                }
            }
        },
        {
            name: 'updated',
            label: 'Updated',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('DD-MM-YYYY HH:mm:ss')
                }
            }
        },
        {
            name: "functionality",
            label: "Functionality",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <TableButtons handleEdit={handleEdit} handleDelete={handleDelete} rowData={tableMeta.rowData} />
                    )
                },
                filter: false,
                sort: false
            }
        },
    ]
    const data = useSelector(state => state.companies.companies);
    const options = {
        responsive: 'standard',
        tableBodyHeight: '85vh',
        tableBodyMaxHeight: '85vh',
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 50, 100, 500],
        verticalAlign: 'middle',
        selectableRowsHeader: true,
        customToolbar: () => (
            <HeaderElements />
        ),
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            // Mock array is used in order to load the multi edit component with initial data
            // Last item, the edit/delete buttons, is removed from the array
            let indexes = selectedRows.data.map(row => row.dataIndex);
            // Remove last item from array
            let mockArray = []
            displayData.forEach(item => {
                if(indexes.includes(item.dataIndex)){
                    let newItem = [...item.data]
                    mockArray.push(newItem.splice(0, newItem.length - 1))
                }
            });
            return(
                <MultiEditButton rowData={mockArray} dt_name={'company'}/>
            )
        }
    }

    return (
        <div className='companies-dashboard h-100 datatable'>
            <MUIDataTable
                title={"Companies"}
                data={data}
                columns={columns}
                options={options}
            />
            {useSelector(state => state.deletePopup.isOpen) && <DeletePopup />}
            {useSelector(state => state.editPopup.isOpen) && <EditPopup />}
        </div>
    )
}

export default CompaniesDashboard