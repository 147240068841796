import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    certificates: []
}
const certificateSlice = createSlice({
    name: "certificate",
    initialState: initialState,
    reducers: {
        setCertificates: (state, action) => {
            state.certificates = action.payload.results;
        },
        deleteCertificate: (state, action) => {
            state.certificates = state.certificates.filter(certificate => certificate.id !== action.payload);
        },
        editCertificate : (state, action) => {
            const {  id, type, name, renewal_times,due_date,date_renewed } = action.payload;
            const certificate = state.certificates.find(certificate => certificate.id === id);
            certificate.type = type;
            certificate.name = name;
            certificate.renewal_times = renewal_times;
            certificate.due_date = due_date;
            certificate.type = type;
            certificate.date_renewed = date_renewed;
        },
    }
});

export const { setCertificates, deleteCertificate, editCertificate } = certificateSlice.actions;
export default certificateSlice.reducer;