import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    systems: []
}
const systemslice = createSlice({
    name: "system",
    initialState: initialState,
    reducers: {
        setSystems: (state, action) => {
            state.systems = action.payload.results;
        },
    }
});

export const { setSystems, deleteSystem, editSystem, addSystem } = systemslice.actions;
export default systemslice.reducer;