import React from 'react'
import UserEditModalBody from './editModalComponents/UserEditModalBody';
import VehicleEditModalBody from './editModalComponents/VehicleEditModalBody';
import SimEditModalBody from './editModalComponents/SimEditModalBody';
import CertificateEditModalBody from './editModalComponents/CertificateEditModalBody';
import CompanyEditModalBody from './editModalComponents/CompanyEditModalBody';

function EditModalBody({datatable}) {
    switch (datatable) {
        case 'user':
            return (
                <UserEditModalBody />
            )
        case 'vehicle':
            return (
                <VehicleEditModalBody />
            )
        case 'sim':
            return (
                <SimEditModalBody />
            )
        case 'certificate':
            return (
                <CertificateEditModalBody />
            )
        case 'company':
            return (
                <CompanyEditModalBody />
            )
        default:
            return (
                <div>{datatable}</div>
            )
    }
}

export default EditModalBody