import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboard:{
        total_users: 0,
        total_vehicles: 0,
        total_sims: 0,
        total_certificates: 0,
        vehicles_last_updated: '00-00-0000 00:00:00',
        sims_renewal_date: '00-00-0000 00:00:00',
        certificates_renewal_date: '00-00-0000 00:00:00',
    }
}
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        setDashboard: (state, action) => {
            state.dashboard = action.payload.results;
        }
    }
});

export const { setDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;