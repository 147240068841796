import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    type: null, 
    name: null, 
    renewal_times: null,
    due_date: null,
    date_renewed: null
}
const tempCertificateSlice = createSlice({    name: "tempCertificate",
    initialState: initialState,
    reducers: {
        setTempCertificate: (state, action) => {
            state.id = parseInt(action.payload.id) || state.id;
            state.type = action.payload.type || state.type;
            state.name = action.payload.name || state.name;
            state.renewal_times = action.payload.renewal_times || state.renewal_times;
            state.due_date = action.payload.due_date || state.due_date;
            state.date_renewed = action.payload.date_renewed || state.date_renewed;
        },
        clearTempCertificate: (state, action) => {
            state.id = null;
            state.type = null;
            state.name = null;
            state.renewal_times = null;
            state.due_date = null;
            state.date_renewed = null;
        }
    }
});

export const { setTempCertificate, clearTempCertificate } = tempCertificateSlice.actions;
export default tempCertificateSlice.reducer;