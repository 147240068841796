import React from 'react'
import axios from 'axios'
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector} from 'react-redux';
import { setAddPopup } from '../../../features/popup/addPopupSlice';
import { setError } from '../../../features/error/errorSlice';
import { addSim } from '../../../features/sim/simSlice';
import { addUser } from '../../../features/user/userSlice';
import { addVehicle } from '../../../features/vehicle/vehicleSlice';
import { addCompany } from '../../../features/company/companySlice';
import AddModalBody from './AddModalBody';

function AddPopup() {
    const dispatch = useDispatch();
    const { datatable } = useSelector(state => state.addPopup);
    const user_data = useSelector(state => state.tempUser);
    const vehicle_data = useSelector(state => state.tempVehicle);
    const sim_data = useSelector(state => state.tempSim);
    const company_data = useSelector(state => state.tempCompany);

    const closeModal = () => {
        const modal = document.querySelector('.edit-modal');
        modal.classList.add('close')
        setTimeout(() => {
            dispatch(setAddPopup({datatable: '', isOpen: false}))
        }, 480)
    }
    const checkBlankValues = (data) => {
        let is_blank = false;
        let blank_fields = [];
        for (let key in data) {
            if ((data[key] === '' || data[key] === null) && key !== 'id') {
                blank_fields.push(key)
                is_blank = true
            }
        }
        return {is_blank, blank_fields}
    }
    const handleSubmit = (url, data, func) => {
        axios.post(`${process.env.REACT_APP_API_URL}${url}`, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            dispatch(func(res.data.results))
            closeModal()
        }).catch(err => {
            if(err.response.status === 403){
                localStorage.removeItem('token')
                window.location.href = '/'
            }else{
                dispatch(setError({
                    isOpen:true, 
                    title: `Error ${err.response.status}`,
                    message: err.response.data.description
                }));
            }
        })
    }
    const handleAdd = () => {
        switch (datatable) {
            case 'user':
                const {u_is_blank, u_blank_fields} = checkBlankValues(user_data)
                if (u_is_blank) {
                    dispatch(setError({
                        isOpen: true,
                        title: 'Wrong Input',
                        message: `The following fields are blank: ${u_blank_fields.join(', ')}`
                    }))
                }else{
                    handleSubmit('/users', user_data, addUser)
                }
                break;
            case 'vehicle':
                const {v_is_blank, v_blank_fields} = checkBlankValues(vehicle_data)
                if (v_is_blank) {
                    dispatch(setError({
                        isOpen: true,
                        title: 'Wrong Input',
                        message: `The following fields are blank: ${v_blank_fields.join(', ')}`
                    }))
                }else{
                    handleSubmit('/vehicles', vehicle_data, addVehicle)
                }
                break;
            case 'sim':
                const {s_is_blank, s_blank_fields} = checkBlankValues(sim_data)
                if (s_is_blank) {
                    dispatch(setError({
                        isOpen: true,
                        title: 'Wrong Input',
                        message: `The following fields are blank: ${s_blank_fields.join(', ')}`
                    }))
                }else{
                    handleSubmit('/sims', sim_data, addSim)
                }
                break;
            case 'company':
                const {co_is_blank, co_blank_fields} = checkBlankValues(company_data)
                if (co_is_blank) {
                    dispatch(setError({
                        isOpen: true,
                        title: 'Wrong Input',
                        message: `The following fields are blank: ${co_blank_fields.join(', ')}`
                    }))
                }else{
                    handleSubmit('/companies', company_data, addCompany)
                }
                break;
            default:
                break;
        }
    }
    return (
        <>
            <div className="modal">
                <div className="modal-content edit-modal overflow-auto">
                    <CloseIcon className='ml-auto close-icon' onClick={closeModal} />
                    <div className="modal-header justify-center">
                        <h3>Add new {datatable.replace(/^./, datatable[0].toUpperCase())}</h3>
                    </div>
                    <AddModalBody datatable={datatable}/>
                    <div className="modal-footer mt-auto">
                        <div className="row pt-1 ml-auto">
                            <Button style={{marginRight: "1rem"}} color="error" variant="outlined" onClick={()=>handleAdd()}>Confirm</Button>
                            <Button variant="outlined" onClick={closeModal}>Cancel</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPopup