import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from './features/sidebar/sidebarSlice';
import dashboardReducer from './features/dashboard/dashboardSlice';
import errorReducer from './features/error/errorSlice';
import userReducer from './features/user/userSlice';
import vehicleReducer from './features/vehicle/vehicleSlice';
import simReducer from './features/sim/simSlice';
import certificateReducer from './features/certificate/certificateSlice';
import systemReducer from './features/system/systemSlice';
import companyReducer from './features/company/companySlice';

import deletePopupReducer from './features/popup/deletePopupSlice';
import editPopupReducer from './features/popup/editPopupSlice';
import addPopupReducer from './features/popup/addPopupSlice';

import tempVehicleSlice from './features/temp/tempVehicleSlice';
import tempUserSlice from './features/temp/tempUserSlice';
import tempSimSlice from './features/temp/tempSimSlice';
import tempSystemSlice from './features/temp/tempSystemSlice';
import tempCertificateSlice from './features/temp/tempCertificateSlice';
import tempCompanySlice from './features/temp/tempCompanySlice';

export const store = configureStore({
    reducer: {
        sidebar: sidebarReducer,
        dashboard: dashboardReducer,
        error: errorReducer,
        users: userReducer,
        vehicles: vehicleReducer,
        sims: simReducer,
        systems: systemReducer,
        certificates: certificateReducer,
        companies: companyReducer,
        deletePopup: deletePopupReducer,
        editPopup: editPopupReducer,
        addPopup: addPopupReducer,
        tempVehicle: tempVehicleSlice,
        tempUser: tempUserSlice,
        tempSim: tempSimSlice,
        tempSystem: tempSystemSlice,
        tempCertificate: tempCertificateSlice,
        tempCompany: tempCompanySlice
    }
});