import React from 'react'
import Sidebar from './Sidebar'
import Dashboard from './Dashboard'
import Error from './general/Error';
import { useSelector } from 'react-redux';

function Home() {
    const { isOpen } = useSelector(state => state.error);
    return (
        <>
            { isOpen && <Error /> }
            <div className='home-container'>
                <Sidebar />
                <Dashboard />
            </div>
        </>
    )
}

export default Home