import React, {useState} from 'react'
import { TextField, MenuItem } from '@mui/material'
import { useDispatch } from 'react-redux';
import { setTempUser } from '../../../../features/temp/tempUserSlice';
import  {InputAdornment, IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function UserAddModalBody() {
    const dispatch = useDispatch();
    const handleChange = (value, type) => {
        dispatch(setTempUser({[type]: value}));
    }
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const validatedValues = [
        {
            value: true,
            label: 'Yes'
        },
        {
            value: false,
            label: 'No'
        }
    ]
    const gdprValues = [
        {
            value: true,
            label: 'Yes'
        },
        {
            value: false,
            label: 'No'
        }
    ]

    return (
        <div className='modal-body'>
            <div className='row w-100 justify-evenly mt-4'>
                <TextField
                    className='col-3'
                    label='Validated'
                    select
                    defaultValue={true}
                    onChange={(e)=> handleChange(e.target.value, 'validated')}
                    helperText="User is validated"
                    style={{padding: '10px', textAlign: 'left'}}
                >
                    {validatedValues.map(option => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <TextField
                    className='col-3'
                    label='GDPR Compliant'
                    select
                    defaultValue={true}
                    onChange={(e)=> handleChange(e.target.value, 'gdpr_compliant')}
                    helperText="User is GDPR Compliant"
                    style={{padding: '10px', textAlign: 'left'}}
                >
                    {gdprValues.map(option => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        )
                    })}
                </TextField>
            </div>
            <div className='row w-100 mt-4'>
                <TextField
                    required
                    className='col-6'
                    label='Email'
                    type='email'
                    helperText="User's email"
                    onChange={(e)=> handleChange(e.target.value, 'email')}
                    style={{padding: '10px'}}
                />
                <TextField
                    required
                    className='col-6'
                    label='Password'
                    type={showPassword ? "text" : "password"}
                    helperText="User's password"
                    onChange={(e)=> handleChange(e.target.value, 'password')}
                    style={{padding: '10px'}}
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    >
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                            )
                    }}
                />
            </div>
        </div>
    )
}

export default UserAddModalBody