import React from 'react'
import { Card, CardHeader, CardContent, List, ListItem, ListItemText  } from '@mui/material';

function Processes({ processes }) {
    return (
        <Card sx={{ width: '100%', height: '100%' }} >
            <CardHeader title='Processes' />
            <CardContent>
                <List>
                    {
                        processes.map( (process, index) => {
                            return (
                                <ListItem key={index}>
                                    {/* Align secondary to the right */}
                                    <ListItemText>
                                        <span><b>{index+1}.</b> {process.name}</span>
                                    </ListItemText>
                                    <ListItemText>
                                        <span style={{float: 'right'}}>{process.memory_percent.toFixed(2)}%</span>
                                    </ListItemText>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </CardContent>
        </Card>
    )
}

export default Processes