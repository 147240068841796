import React from 'react'
import { TextField, MenuItem } from '@mui/material'
import { useDispatch } from 'react-redux';
import { setTempVehicle } from '../../../../features/temp/tempVehicleSlice';

function VehicleAddModalBody() {
    const dispatch = useDispatch();

    const handleChange = (value, type) => {
        dispatch(setTempVehicle({[type]: value}));
    }
    const fuel_types = [
        {
            value: 1,
            label: 'Electric'
        },
        {
            value: 2,
            label: 'Diesel'
        },
        {
            value: 3,
            label: 'Unleaded'
        },
        {
            value: 4,
            label: 'Leaded'
        },
        {
            value: 5,
            label: 'Petrol'
        }
    ]
    const capacity_types = [
        {
            value: 'cc',
            label: 'CC'
        },
        {
            value: 'kwh',
            label: 'KWH'
        }
    ]
    const providers = [
        {
            value: 'bce',
            label: 'BCE'
        },
        {
            value: 'cello',
            label: 'CELLO'
        },
        {
            value: 'teltonika',
            label: 'TELTONIKA'
        },
        {
            value: 'ulu',
            label: 'ULU'
        },
        {
            value: 'bot',
            label: 'BOT'
        }
    ]
    return (
        <div className='modal-body'>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Fuel type'
                        select
                        defaultValue={1}
                        onChange={(e) => handleChange(e.target.value, 'fid')}
                        fullWidth
                        style={{padding: '10px', textAlign: 'left'}}
                    >
                        {fuel_types.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='IMEI'
                        onChange={(e) => handleChange(e.target.value, 'imei')}
                        fullWidth
                        type="number"
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Capacity'
                        onChange={(e) => handleChange(e.target.value, 'capacity')}
                        fullWidth
                        type="number"
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Capacity Type'
                        select
                        defaultValue={'cc'}
                        onChange={(e) => handleChange(e.target.value, 'capacity_type')}
                        fullWidth
                        style={{padding: '10px', textAlign: 'left'}}
                    >
                        {capacity_types.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='CC / KWH'
                        onChange={(e) => handleChange(e.target.value, 'cc')}
                        fullWidth
                        type="number"
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Color'
                        onChange={(e) => handleChange(e.target.value, 'color')}
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Plate'
                        onChange={(e) => handleChange(e.target.value, 'plate')}
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Provider'
                        select
                        defaultValue={'teltonika'}
                        onChange={(e) => handleChange(e.target.value, 'provider')}
                        fullWidth
                        style={{padding: '10px', textAlign: 'left'}}
                    >
                        {providers.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Seats'
                        type="number"
                        onChange={(e) => handleChange(e.target.value, 'seats')}
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='row w-100 mt-4'>
                    <div className='col-4'>
                        <TextField
                            label='VIN'
                            onChange={(e) => handleChange(e.target.value, 'vin')}
                            fullWidth
                            style={{padding: '10px'}}
                        />
                    </div>
                    <div className='col-4'>
                        <TextField
                            label='Year'
                            type="date"
                            onChange={(e) => handleChange(e.target.value, 'year')}
                            fullWidth
                            style={{padding: '10px'}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleAddModalBody