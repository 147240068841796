import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    validated: false, 
    gdpr_compliant: false, 
    email: null,
    password: null
}
const tempUserSlice = createSlice({
    name: "tempUser",
    initialState: initialState,
    reducers: {
        setTempUser: (state, action) => {
            state.id = action.payload.id || state.id;
            state.validated = action.payload.validated !== undefined ? action.payload.validated : state.validated;
            state.gdpr_compliant = action.payload.gdpr_compliant !== undefined ? action.payload.gdpr_compliant : state.gdpr_compliant;
            state.email = action.payload.email || state.email;
            state.password = action.payload.password || state.password;
        },
        clearTempUser: (state, action) => {
            state.id = null;
            state.validated = false;
            state.gdpr_compliant = false;
            state.email = null;
            state.password = null;
        }
    }
});

export const { setTempUser, clearTempUser } = tempUserSlice.actions;
export default tempUserSlice.reducer;