import React from 'react'
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useDispatch, useSelector} from 'react-redux';
import { deleteUser } from '../../../features/user/userSlice';
import { deleteVehicle } from '../../../features/vehicle/vehicleSlice';
import { deleteSim } from '../../../features/sim/simSlice';
import { deleteCertificate } from '../../../features/certificate/certificateSlice';
import { setDeletePopup } from '../../../features/popup/deletePopupSlice';
import { setError } from '../../../features/error/errorSlice';

function DeletePopup() {
    const dispatch = useDispatch();
    const { datatable, rowData } = useSelector(state => state.deletePopup);
    const info = ()=>{
        switch (datatable) {
            case 'user':
                return rowData[2]
            case 'vehicle':
                return `${rowData[9]} - ${rowData[8]}`
            case 'sim':
                return `${rowData[1]} - ${rowData[5]} - ${rowData[6]}`
            case 'certificate':
                return `${rowData[3]} - ${rowData[1]}`
            case 'company':
                return `${rowData[1]} - ${rowData[2]}`
            default:
                return ''
        }
    }
    const handleDelete = () => {
        const row_id = rowData[0];
        axios.delete(`${process.env.REACT_APP_API_URL}/${datatable}s/${row_id}`,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*'
            }
        })
        .then(res => {
            switch(datatable) {
                case 'user':
                    dispatch(deleteUser( row_id ))
                    break;
                case 'vehicle':
                    dispatch(deleteVehicle( row_id ))
                    break;
                case 'sim':
                    dispatch(deleteSim( row_id ))
                    break;
                case 'certificate':
                    dispatch(deleteCertificate( row_id ))
                    break;
                default:
                    break;
            }
            closeModal();
        })
        .catch(err => {
            if(err.response.status === 403){
                localStorage.removeItem('token')
                window.location.href = '/'
            }else{
                dispatch(setError({
                    isOpen:true, 
                    title: `Error ${err.response.status}`,
                    message: err.response.data.description
                }));
            }
        })
    }
    const closeModal = () => {
        const modal = document.querySelector('.delete-modal');
        modal.classList.add('close')
        setTimeout(() => {
            dispatch(setDeletePopup(false))
        }, 480)
    }
    return (
        <div className="modal">
            <div className="modal-content delete-modal">
                <CloseIcon className='ml-auto close-icon' onClick={closeModal} />
                <div className="modal-header justify-center">
                    <h3>Delete {datatable.replace(/^./, datatable[0].toUpperCase())}</h3>
                </div>
                <div className="modal-body">
                    <p className='pt-3'>Are you sure you want to delete this {datatable}?</p>
                    <p className='pt-1'>
                        <b>{info()}</b>
                    </p>
                </div>
                <div className="modal-footer mt-auto">
                    <div className="row pt-1 ml-auto">
                        <Button style={{marginRight: "1rem"}} color="error" variant="outlined" onClick={()=>handleDelete()}>Confirm</Button>
                        <Button variant="outlined" onClick={closeModal}>Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeletePopup