import React from 'react'
import { useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import EditModalBody from './EditModalBody';
import { setEditPopup } from '../../../features/popup/editPopupSlice';
import { editUser } from '../../../features/user/userSlice';
import { editVehicle } from '../../../features/vehicle/vehicleSlice';
import { editCertificate } from '../../../features/certificate/certificateSlice';
import { editCompany } from '../../../features/company/companySlice';
import { editSim } from '../../../features/sim/simSlice';
import { setError } from '../../../features/error/errorSlice';
import axios from 'axios';

function EditPopup() {
    const dispatch = useDispatch();
    const { rowData, datatable, multiple } = useSelector(state => state.editPopup);
    const userData = useSelector(state => state.tempUser);
    const vehicleData = useSelector(state => state.tempVehicle);
    const simData = useSelector(state => state.tempSim);
    const certificateData = useSelector(state=> state.tempCertificate)
    const companyData = useSelector(state => state.tempCompany);

    const closeModal = () => {
        const modal = document.querySelector('.edit-modal');
        modal.classList.add('close')
        setTimeout(() => {
            dispatch(setEditPopup(false))
        }, 480)
    }
    const checkBlankValues = (data) => {
        let isBlank = false;
        let blankFields = [];
        for (let key in data) {
            if ((data[key] === '' || data[key] === null) && key !== 'id') {
                blankFields.push(key)
                isBlank = true
            }
        }
        return {isBlank, blankFields}
    }
    const handleSubmit = (url, data, func) => {
        const send_url = `${process.env.REACT_APP_API_URL}${url}/${data.id}`;
        axios.put(send_url, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            dispatch(func(res.data.results))
            closeModal()
        }).catch(err => {
            if(err.response.status === 403){
                localStorage.removeItem('token')
                window.location.href = '/'
            }else{
                dispatch(setError({
                    isOpen:true, 
                    title: `Error ${err.response.status}`,
                    message: err.response.data.description
                }));
            }
        })
    }
    const handleEdit = () => {
        switch (datatable) {
            case 'user':
                if(multiple){
                    rowData.forEach(row => {
                        const userMultiple = {
                            id: row[0],
                            validated: userData.validated,
                            email: userData.email || row[2],
                            gdpr_compliant: userData.gdpr_compliant,
                            password: null
                        }
                        handleSubmit('/users', userMultiple, editUser)
                    })
                }else{
                    const {u_is_blank, u_blank_fields} = checkBlankValues(userData)
                    if (u_is_blank) {
                        dispatch(setError({
                            isOpen: true,
                            title: 'Wrong Input',
                            message: `The following fields are blank: ${u_blank_fields.join(', ')}`
                        }))
                    }else{
                        handleSubmit('/users', userData, editUser)
                    }
                }
                break;
            case 'vehicle':
                if(multiple){
                    rowData.forEach(row => {
                        const vehicleMultiple = {
                            id: row[0],
                            fid: vehicleData.fid || row[1],
                            imei: vehicleData.imei || row[3],
                            capacity: vehicleData.capacity || row[4],
                            capacity_type: vehicleData.capacity_type || row[5],
                            cc: vehicleData.cc || row[6],
                            color: vehicleData.color || row[7],
                            plate: vehicleData.plate || row[8],
                            provider: vehicleData.provider || row[9],
                            seats: vehicleData.seats || row[10],
                            vin: vehicleData.vin || row[12],
                            year: vehicleData.year || row[13]
                        }
                        handleSubmit('/vehicles', vehicleMultiple, editVehicle)
                    })
                }else{
                    const {v_is_blank, v_blank_fields} = checkBlankValues(vehicleData)
                    if (v_is_blank) {
                        dispatch(setError({
                            isOpen: true,
                            title: 'Wrong Input',
                            message: `The following fields are blank: ${v_blank_fields.join(', ')}`
                        }))
                    }else{
                        handleSubmit('/vehicles', vehicleData, editVehicle)
                    }
                }
                break;
            case 'sim':
                if(multiple){
                    rowData.forEach(row => {
                        let active = false
                        if (simData.active !== null){
                            active = simData.active
                        }else{
                            active = row[3] === 'Active' ? true : false
                        }
                        const simMultiple = {
                            id: row[0],
                            user: simData.user || row[1],
                            activation_date: simData.activation_date || row[2],
                            active: active,
                            imei:  parseInt(simData.imei) ||  parseInt(row[4]),
                            provider: simData.provider || row[5],
                            phone_number:  parseInt(simData.phone_number) ||  parseInt(row[6]),
                            plate: simData.plate || row[7],
                            price:  parseInt(simData.price) ||  parseInt(row[8]),
                            renewal_date: simData.renewal_date || row[9],
                            sim_vin: simData.sim_vin || row[10],
                            type: simData.type || row[11],
                        }
                        handleSubmit('/sims', simMultiple, editSim)
                    })
                }else{
                    const {s_is_blank, s_blank_fields} = checkBlankValues(simData)
                    if (s_is_blank) {
                        dispatch(setError({
                            isOpen: true,
                            title: 'Wrong Input',
                            message: `The following fields are blank: ${s_blank_fields.join(', ')}`
                        }))
                    }else{
                        handleSubmit('/sims', simData, editSim)
                    }
                }
                break;
            case 'certificate':
                if(multiple){
                    rowData.forEach(row => {
                        let due_date = certificateData.due_date !== null ? moment(certificateData.due_date, "YYYY-MM-DDTHH:mm").format('YYYY-MM-DD HH:mm:ss') : moment(row[4], "MMMM Do YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss')
                        let date_renewed = certificateData.date_renewed !== null ? moment(certificateData.date_renewed, "YYYY-MM-DDTHH:mm").format('YYYY-MM-DD HH:mm:ss') : moment(row[5], "MMMM Do YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss')
                        const certificateMultiple = {
                            id: row[0],
                            type: certificateData.type || row[1],
                            name: certificateData.name || row[2],
                            renewal_times: certificateData.renewal_times || row[3],
                            due_date: due_date,
                            date_renewed: date_renewed,
                        }
                        handleSubmit('/certificates', certificateMultiple, editCertificate)
                    })
                }else{
                    const {c_is_blank, c_blank_fields} = checkBlankValues(certificateData)
                    if (c_is_blank) {
                        dispatch(setError({
                            isOpen: true,
                            title: 'Wrong Input',
                            message: `The following fields are blank: ${c_blank_fields.join(', ')}`
                        }))
                    }else{
                        handleSubmit('/certificates', certificateData, editCertificate)
                    }
                }
                break;
            case 'company':
                if(multiple){
                    rowData.forEach(row => {
                        const companyMultiple = {
                            id: row[0],
                            name: companyData.name || row[1],
                            alias: companyData.alias || row[2],
                            type: companyData.type || row[3],
                            vat: companyData.vat || row[4],
                            tax_office: companyData.tax_office || row[5],
                            phone_code_prefix: companyData.phone_code_prefix || row[6],
                            phone: companyData.phone || row[7],
                            emergency_phone: companyData.emergency_phone || row[8],
                            address: companyData.address || row[9],
                            postcode: companyData.postcode || row[10],
                            city: companyData.city || row[11],
                            country: companyData.country || row[12],
                            currency: companyData.currency || row[13],
                            locales: companyData.locales || row[14],
                            latitude: companyData.latitude || row[15],
                            longitude: companyData.longitude || row[16],
                            cardlink: companyData.cardlink === "Yes" ? 1 : 0 || row[17] === "Yes" ? 1 : 0,
                            viva: companyData.viva === "Yes" ? 1 : 0 || row[18] === "Yes" ? 1 : 0,
                            viva_native: companyData.viva_native === "Yes" ? 1 : 0 || row[19] === "Yes" ? 1 : 0,
                            paypal: companyData.paypal === "Yes" ? 1 : 0 || row[20] === "Yes" ? 1 : 0,
                            paymentexpress: companyData.paymentexpress === "Yes" ? 1 : 0 || row[21] === "Yes" ? 1 : 0,
                            gocardless: companyData.gocardless === "Yes" ? 1 : 0 || row[22] === "Yes" ? 1 : 0,
                            mass_cancellation: companyData.mass_cancellation === "Yes" ? 1 : 0 || row[23] === "Yes" ? 1 : 0,
                            mass_lock: companyData.mass_lock === "Yes" ? 1 : 0 || row[24] === "Yes" ? 1 : 0,
                            disable_immobilizer_if_reservation: companyData.disable_immobilizer_if_reservation === "Yes" ? 1 : 0 || row[25] === "Yes" ? 1 : 0,
                            unreserved_vehicle_alert: companyData.unreserved_vehicle_alert === "Yes" ? 1 : 0 || row[26] === "Yes" ? 1 : 0,
                            battery_level_alert: companyData.battery_level_alert === "Yes" ? 1 : 0 || row[27] === "Yes" ? 1 : 0,
                            fuel_level_alert: companyData.fuel_level_alert === "Yes" ? 1 : 0 || row[28] === "Yes" ? 1 : 0,
                            lost_signal_alert: companyData.lost_signal_alert === "Yes" ? 1 : 0 || row[29] === "Yes" ? 1 : 0,
                            driver_license_expiration_alert: companyData.driver_license_expiration_alert === "Yes" ? 1 : 0 || row[30] === "Yes" ? 1 : 0,
                            speed_limit_alert: companyData.speed_limit_alert === "Yes" ? 1 : 0 || row[31] === "Yes" ? 1 : 0,
                            partial_charge_reservation: companyData.partial_charge_reservation === "Yes" ? 1 : 0 || row[32] === "Yes" ? 1 : 0,
                            active: companyData.active === "Yes" ? 1 : 0 || row[33] === "Yes" ? 1 : 0,
                            access_token: companyData.access_token || row[34],
                            firebase_server_key: companyData.firebase_server_key || row[35],
                            android_version: companyData.android_version || row[36],
                            ios_version: companyData.ios_version || row[37],
                            force_mobile_app_update: companyData.force_mobile_app_update  === "Yes" ? 1 : 0 || row[38] === "Yes" ? 1 : 0,
                            member_personal_required_fields: companyData.member_personal_required_fields || row[39],
                            customer_billing_required_fields: companyData.customer_billing_required_fields || row[40],
                            send_invoice_to: companyData.send_invoice_to || row[41]
                        }
                        handleSubmit('/companies', companyMultiple, editCompany)
                    })
                }else{
                    const {co_is_blank, co_blank_fields} = checkBlankValues(companyData)
                    if (co_is_blank) {
                        dispatch(setError({
                            isOpen: true,
                            title: 'Wrong Input',
                            message: `The following fields are blank: ${co_blank_fields.join(', ')}`
                        }))
                    }else{
                        handleSubmit('/companies', companyData, editCompany)
                    }
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="modal">
            <div className="modal-content edit-modal overflow-auto">
                <CloseIcon className='ml-auto close-icon' onClick={closeModal} />
                <div className="modal-header justify-center">
                    <h3>Edit {
                        multiple === true 
                        ? `Multiple ${datatable.replace(/^./, datatable[0].toUpperCase())}s` 
                        : datatable.replace(/^./, datatable[0].toUpperCase())
                    }</h3>
                </div>
                <EditModalBody datatable={datatable} />
                <div className="modal-footer mt-auto">
                    <div className="row pt-1 ml-auto">
                        <Button style={{marginRight: "1rem"}} color="error" variant="outlined" onClick={()=>handleEdit()}>Confirm</Button>
                        <Button variant="outlined" onClick={closeModal}>Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPopup