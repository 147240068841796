import React, {useEffect} from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { useDispatch, useSelector} from 'react-redux';
import { setVehicles } from '../../features/vehicle/vehicleSlice';
import { setDeletePopup } from '../../features/popup/deletePopupSlice';
import { setEditPopup } from '../../features/popup/editPopupSlice';
import TableButtons from '../general/buttons/TableButtons';
import DeletePopup from '../general/modal/DeletePopup';
import AddNewButton from '../general/buttons/AddNewButton';
import EditPopup from '../general/modal/EditPopup';
import MultiEditButton from '../general/buttons/MultiEditButton';
import { setError } from '../../features/error/errorSlice';

function VehiclesDashboard() {
    const dispatch = useDispatch();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/vehicles`,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            dispatch(setVehicles( res.data ))
        })
        .catch(err => {
            if(err.response.status === 403){
                localStorage.removeItem('token')
                window.location.href = '/'
            }else{
                dispatch(setError({
                    isOpen:true, 
                    title: `Error ${err.response.status}`,
                    message: err.response.data.description
                }));
            }
        })
    }, [dispatch])

    const HeaderElements = () => (
        <AddNewButton dt_name={'vehicle'}/>
    );
    const handleEdit = (rowData) => {
        dispatch(setEditPopup({
            datatable: 'vehicle',
            isOpen: true,
            rowData: rowData
        }))
    }
    const handleDelete = (rowData) => {
        dispatch(setDeletePopup({
            datatable: 'vehicle',
            isOpen: true,
            rowData: rowData
        }))
    }
    const columns = [
        {
            name: "id",
            label: "ID"
        },
        {
            name: "fid",
            label: "Fuel type",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    switch (value) {
                        case 1:
                            return 'Electric'
                        case 2:
                            return 'Diesel'
                        case 3:
                            return 'Unleaded'
                        case 4:
                            return 'Leaded'
                        case 5:
                            return 'Petrol'
                        default:
                            return 'Unknown'
                    }
                }
            }
        },
        {
            name: "mid",
            label: "MID"
        },
        {
            name: "imei",
            label: "IMEI"
        },
        {
            name: "capacity",
            label: "Capacity"
        },
        {
            name: "capacity_type",
            label: "Capacity Type"
        },
        {
            name: "cc",
            label: "CC"
        },
        {
            name: "color",
            label: "Color"
        },
        {
            name: "plate",
            label: "Plate"
        },
        {
            name: "provider",
            label: "Provider"
        },
        {
            name: "seats",
            label: "Seats"
        },
        {
            name: "tid",
            label: "TID"
        },
        {
            name: "vin",
            label: "VIN"
        },
        {
            name: "year",
            label: "Year"
        },
        {
            name: "created",
            label: "Created",
            options: {
                filter: false
            }
        },
        {
            name: "updated",
            label: "Updated",
            options: {
                filter: false
            }
        },
        {
            name: "functionality",
            label: "Functionality",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <TableButtons handleEdit={handleEdit} handleDelete={handleDelete} rowData={tableMeta.rowData} />
                    )
                },
                filter: false,
                sort: false
            }
        }
    ]
    const data = useSelector(state => state.vehicles.vehicles);
    const options = {
        responsive: 'standard',
        tableBodyHeight: '85vh',
        tableBodyMaxHeight: '85vh',
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 50, 100, 500],
        verticalAlign: 'middle',
        selectableRowsHeader: true,
        customToolbar: () => (
            <HeaderElements />
        ),
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            // Mock array is used in order to load the multi edit component with initial data
            // Last item, the edit/delete buttons, is removed from the array
            let indexes = selectedRows.data.map(row => row.dataIndex);
            // Remove last item from array
            let mockArray = []
            displayData.forEach(item => {
                if(indexes.includes(item.dataIndex)){
                    let newItem = [...item.data]
                    mockArray.push(newItem.splice(0, newItem.length - 1))
                }
            });
            return(
                <MultiEditButton rowData={mockArray} dt_name={'vehicle'}/>
            )
        }
    }

    return (
        <div className='vehicles-dashboard h-100 datatable'>
            <MUIDataTable
                title={"Vehicles"}
                data={data}
                columns={columns}
                options={options}
            />
            {useSelector(state => state.deletePopup.isOpen) && <DeletePopup />}
            {useSelector(state => state.editPopup.isOpen)   && <EditPopup />}
        </div>
    )
}

export default VehiclesDashboard