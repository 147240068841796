import React from 'react'
import { Card, CardHeader, CardContent } from '@mui/material'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function CpuChart({ cpu }) {
    return (
        <Card sx={{ minWidth: 250, padding: '20px', height: '100%' }} >
            <CardHeader sx={{ padding: 0 }} title='CPU Usage (%)' />
            <CardContent>
                <ResponsiveContainer aspect={3} width='100%'>
                    <LineChart 
                        data={cpu}
                        margin={{ top: 10, right: 10, left: 20, bottom: 10 }}
                    >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='time'/>
                        <YAxis allowDecimals={false} domain={[0, 100]} label={{ value: 'Percent (%)', angle: -90, position: 'left' }} />
                        <Tooltip />
                        <Legend verticalAlign='top' height={35} fontSize={100} />
                        <Line name='Cpu usage (%)' type='linear' dataKey='cpu' stroke='#8884d8' activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    )
}

export default CpuChart