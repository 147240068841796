import React from 'react'
import { TextField, MenuItem } from '@mui/material'
import { useDispatch } from 'react-redux';
import { setTempSim } from '../../../../features/temp/tempSimSlice';

function SimAddModalBody() {
    const dispatch = useDispatch();
    const handleChange = (value, type) => {
        dispatch(setTempSim({[type]: value}));
    }
    const active_values = [
        {
            value: true,
            label: 'Active'
        },
        {
            value: false,
            label: 'Inactive'
        }
    ]
    const provider_values = [
        {
            value: 'BCE',
            label: 'BCE'
        },
        {
            value: 'CELLO',
            label: 'CELLO'
        },
        {
            value: 'TELTONIKA',
            label: 'TELTONIKA'
        },
        {
            value: 'ULU',
            label: 'ULU'
        }
    ]
    return (
        <div className='modal-body'>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='User'
                        onChange={(e)=> handleChange(e.target.value, 'user')}
                        helperText="Client's name/ alias"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Activation Date'
                        type={'date'}
                        onChange={(e)=> handleChange(e.target.value, 'activation_date')}
                        helperText="Date of activation"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Active'
                        select
                        defaultValue={true}
                        onChange={(e)=> handleChange(e.target.value, 'active')}
                        helperText="Sim is active or not"
                        fullWidth
                        style={{padding: '10px', textAlign: 'left'}}
                    >
                        {active_values.map(option => {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='IMEI'
                        type={'number'}
                        onChange={(e)=> handleChange(e.target.value, 'imei')}
                        helperText="IMEI number of the device"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Provider'
                        select
                        onChange={(e)=> handleChange(e.target.value, 'provider')}
                        helperText="CarSharing device provider"
                        fullWidth
                        style={{padding: '10px', textAlign: 'left'}}
                    >
                        {provider_values.map(option => {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </div>
                <div className='col-4'>
                    <TextField
                        label='Phone Number'
                        type={'number'}
                        onChange={(e)=> handleChange(e.target.value, 'phone_number')}
                        helperText="Phone number of the device"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='Plate'
                        onChange={(e)=> handleChange(e.target.value, 'plate')}
                        helperText="Plate number of the vehicle"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Price'
                        type={'number'}
                        onChange={(e)=> handleChange(e.target.value, 'price')}
                        helperText="Price of the sim"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Renewal Date'
                        type={'date'}
                        onChange={(e)=> handleChange(e.target.value, 'renewal_date')}
                        helperText="Date of renewal"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
            <div className='row w-100 justify-evenly mt-4'>
                <div className='col-4'>
                    <TextField
                        label='VIN'
                        onChange={(e)=> handleChange(e.target.value, 'sim_vin')}
                        helperText="VIN number of the vehicle"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
                <div className='col-4'>
                    <TextField
                        label='Type'
                        onChange={(e)=> handleChange(e.target.value, 'type')}
                        helperText="Vehicle type/ model"
                        fullWidth
                        style={{padding: '10px'}}
                    />
                </div>
            </div>
        </div>
    )
}

export default SimAddModalBody