import React from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useDispatch, useSelector } from 'react-redux'
import { setError } from '../../features/error/errorSlice';

function Error() {
    const dispatch = useDispatch();
    const {title, message} = useSelector(state => state.error);
    const handleClose = () => {
        dispatch(setError({
            isOpen: false,
            title: '',
            message: ''
        }));
    };
    return (
        <>
            { message && 
                <div className='error'>
                    <h2 className="p-absolute">{title}</h2>
                    <CloseRoundedIcon className='error-close' onClick={handleClose}/>
                    <p className='error-msg mt-3'>{message}</p>
                </div>
            }
        </>
    )
}

export default Error