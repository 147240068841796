import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import HomeDashboard from './dashboard/HomeDashboard'
import UsersDashboard from './dashboard/UsersDashboard'
import VehiclesDashboard from './dashboard/VehiclesDashboard'
import SimsDashboard from './dashboard/SimsDashboard'
import SystemDashboard from './dashboard/SystemDashboard'
import CertificatesDashboard from './dashboard/CertificatesDashboard'
import CompaniesDashboard from './dashboard/CompaniesDashboard'
import { setDashboard } from '../features/dashboard/dashboardSlice'
import { setError } from '../features/error/errorSlice'

function Dashboard() {
    const dispatch = useDispatch()
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/dashboard`,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            dispatch(setDashboard( res.data ))
        })
        .catch(err => {
            if(err.response.status === 403){
                localStorage.removeItem('token')
                window.location.href = '/'
            }else{
                dispatch(setError({
                    isOpen:true, 
                    title: `Error ${err.response.status}`,
                    message: err.response.data.description
                }));
            }
        })
    }, [dispatch])
    const { selected } = useSelector(state => state.sidebar);
    switch (selected) {
        case 'home':
            return (
                <div className='dashboard dashboard-sidebar-is-closed'>
                    <HomeDashboard />
                </div>
            )
        case 'users':
            return (
                <div className='dashboard dashboard-sidebar-is-closed'>
                    <UsersDashboard />
                </div>
            )
        case 'vehicles':
            return (
                <div className='dashboard dashboard-sidebar-is-closed'>
                    <VehiclesDashboard />
                </div>
            )
        case 'system':
            return (
                <div className='dashboard dashboard-sidebar-is-closed'>
                    <SystemDashboard/>
                </div>
            )
        case 'sims':
            return (
                <div className='dashboard dashboard-sidebar-is-closed'>
                    <SimsDashboard />
                </div>
            )
        case 'certificates':
            return (
                <div className='dashboard dashboard-sidebar-is-closed'>
                    <CertificatesDashboard />
                </div>
            )
        case 'companies':
            return (
                <div className='dashboard dashboard-sidebar-is-closed'>
                    <CompaniesDashboard />
                </div>
            )
        case 'logout':
            return (
                <div className='dashboard dashboard-sidebar-is-closed'>{selected}</div>
            )
        default:
            return (
                <div className='dashboard dashboard-sidebar-is-closed'>
                    <HomeDashboard />
                </div>
            )
    }
}

export default Dashboard;